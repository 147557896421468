import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { IoClose } from "react-icons/io5";

const CreateAccountPopup = ({
    globalRefs,
    globalState,
}) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const isCreateAccountPopupVisible = globalState.isCreateAccountPopupVisible;

    const handleMouseMove = (e) => {
        setPosition({ x: e.clientX + 150, y: e.clientY - 30 });
    };

    useEffect(() => {
        if (isCreateAccountPopupVisible) {
            document.addEventListener('mousemove', handleMouseMove);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isCreateAccountPopupVisible]);

    const popupStyle = {
        position: 'absolute',
        left: `${position.x + 10}px`, // Slight offset for better visibility
        top: `${position.y + 10}px`,   // Slight offset for better visibility
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background
        color: '#fff',                  // Text color
        padding: '10px',                // Padding around the text
        borderRadius: '5px',            // Rounded corners
        zIndex: 1000,                   // Ensure it appears above other elements
    };

    if (!isCreateAccountPopupVisible) return null; // Don't render if not visible

    return (
        <>
            <div className="create-account-popup" style={popupStyle}>
                <div className="create-account-popup-inner">
                   Create an account to access this feature.
                </div>
            </div>
        </>
    );
};

export default CreateAccountPopup;
