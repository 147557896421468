import React, { useEffect, useRef } from "react";
import 'react-modern-drawer/dist/index.css';
import Chart from "chart.js/auto";
import { presetColors } from '../../../../libs/colours.js';

const ComparePanel = ({
    longstandingGeoJSONRef,
    originalGeoJSONforComparingRef,
    selectedMetric,
    universalParcelDifferentiator,
    selectedYearEditing
}) => {
    const currentChartRef = useRef(null);
    const originalChartRef = useRef(null);

    const initChart = (chartRef, chartId, datasets) => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }
        const ctx = document.getElementById(chartId);
        if (ctx) {
            chartRef.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: datasets.labels,
                    datasets: datasets.datasets
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        title: { display: false },
                        legend: { display: true }
                    },
                    scales: {
                        y: {
                            beginAtZero: true
                        },
                        x: {
                            display: true,
                            stacked: false,
                            grid: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false,
                                maxRotation: 0
                            },
                            barPercentage: 0.4,
                            categoryPercentage: 0.5
                        }
                    }
                }
            });
        }
    };

    const parseGeoJSON = (geojson, selectedYearEditing) => {
        const cumulativeDataMap = new Map();
        geojson.features.forEach(feature => {
            const parcelId = feature.feature_data_simple_timeseries[universalParcelDifferentiator];
            let value = 0;
            if (feature.feature_data_simple_timeseries[selectedMetric]) {
                value = feature.feature_data_simple_timeseries[selectedMetric][selectedYearEditing] || 0;
            }
            cumulativeDataMap.set(parcelId, (cumulativeDataMap.get(parcelId) || 0) + value);
        });
        const sortedData = Array.from(cumulativeDataMap.entries()).sort((a, b) => b[1] - a[1]);
        const labels = sortedData.map(entry => entry[0]);
        const data = sortedData.map(entry => entry[1]);
        return { labels, data };
    };

    useEffect(() => {
        if (longstandingGeoJSONRef.current && originalGeoJSONforComparingRef.current) {
            const data1 = parseGeoJSON(longstandingGeoJSONRef.current, selectedYearEditing);
            const data2 = parseGeoJSON(originalGeoJSONforComparingRef.current, selectedYearEditing);

            // Prepare datasets for the current chart
            const currentDatasets = {
                labels: data1.labels,
                datasets: [
                    {
                        label: 'Current Data',
                        data: data1.data,
                        backgroundColor: presetColors['current'], // Change as needed
                    },
                ]
            };

            // Prepare datasets for the original chart
            const originalDatasets = {
                labels: data2.labels,
                datasets: [
                    {
                        label: 'Original Data',
                        data: data2.data,
                        backgroundColor: presetColors['original'], // Change as needed
                    },
                ]
            };

            // Initialize both charts
            initChart(currentChartRef, 'currentChart', currentDatasets);
            initChart(originalChartRef, 'originalChart', originalDatasets);
        }
    }, [longstandingGeoJSONRef.current, originalGeoJSONforComparingRef.current, selectedMetric, selectedYearEditing]);

    return (
        <div className='compare'>
            <h2>Compare data</h2>
            <div className="compare__container_one">
                <div className='compare__container__content__item__content'>
                    <p>Comparison of {selectedMetric} per {universalParcelDifferentiator}</p>
                    <canvas id="currentChart"></canvas>
                </div>
                <div className='compare__container__content__item__content'>
                    <p>Original Data Comparison</p>
                    <canvas id="originalChart"></canvas>
                </div>
            </div>
        </div>
    );
}

export default ComparePanel;
