import {
  loading_timer_count,
  fmesh,
  fullmesh
} from './globalVariables.js';
import SkylarkPolygonLayer from '../core/skylarkPolygonLayer';

const ResetMap = async (globalRefs, globalState) => {

  let mapRef = globalRefs.mapRef;
  let drawRef = globalRefs.drawRef;
  let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
  let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  let selectedYearEditing = globalState.selectedYearEditing;
  let setIsLanduse = globalState.setIsLanduse;
  let setisLeftSubDrawerOpen = globalState.setisLeftSubDrawerOpen;
  let hasCreatedNewPolygonInModify = globalState.hasCreatedNewPolygonInModify;
  let setHasCreatedNewPolygonInModify = globalState.setHasCreatedNewPolygonInModify;
  let isDrawing = globalState.isDrawing;
  let centroidRef = globalRefs.centroidRef;
  let universalParcelDifferentiator = globalState.universalParcelDifferentiator;
  let universalColorPaletteName = globalState.universalColorPaletteName;

  if (isDrawing) {
    mapRef.current.removeControl(drawRef.current);
  }

  fmesh.children.forEach((child) => {
    child.geometry.dispose();
    child.material.dispose();
  });
  fullmesh.children.forEach((child) => {
    fullmesh.remove(child);
  });
  fmesh.children.forEach((child) => {
    fmesh.remove(child);
  });
  fmesh.children.length = 0;
  fullmesh.children.length = 0;
  mapRef.current.removeLayer('Polygons');
  polygonCustomLayerRef.current = new SkylarkPolygonLayer(
    'Polygons',
    centroidRef.current.geometry.coordinates[1],
    centroidRef.current.geometry.coordinates[0],
    longstandingGeoJSONRef.current,
    globalRefs,
    globalState,
    universalColorPaletteName,
    universalParcelDifferentiator
  );
  mapRef.current.addLayer(polygonCustomLayerRef.current);
  if (hasCreatedNewPolygonInModify !== "") {
    setIsLanduse(true);
    polygonCustomLayerRef.current.createdNewFeatures(hasCreatedNewPolygonInModify, selectedYearEditing);
    setHasCreatedNewPolygonInModify("");
    setisLeftSubDrawerOpen(true);
  }

}

export default ResetMap;