
import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import html2canvas from 'html2canvas';

import './App.css';
import './Custom.css';
import './fileInterface.css';

import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';
import { useNavigate, useLocation } from 'react-router-dom';

// icons
import { MdAccountCircle } from "react-icons/md";

// draw
import ResetMap from './components/core/resetMap.js';
import initializeMapboxDraw from './components/core/initializeMapboxDraw.js';

// dataManagement
import saveDataWithFilename from './components/dataManagement/saveDataWithFilename.js';
import saveCurrentDataSet from './components/dataManagement/saveCurrentDataSet.js';

// ui
import TopMenu from './components/ui/menus/topMenu.js';
import LoadingSpinner from './components/ui/modals/loadingSpinnerModal.js';
import ClickedInfo from './components/ui/modals/clickedInfoModal.js';
import TopSubMenu from './components/ui/menus/topSubMenu.js';
import LeftMenu from './components/ui/menus/leftMenu.js';
import LeftSubMenu from './components/ui/menus/leftSubMenu.js';
import ComparePanel from './components/ui/menus/leftSubMenuComponents/comparePanel.js';
import GeneralNav from './components/ui/modals/mapViewModal.js';
import LoadMenu from './components/dataManagement/dataLoad.js';
import FilenameInputModal from './components/ui/modals/filenameInputModal.js';
import SettingsPanel from './components/ui/menus/leftSubMenuComponents/settingsPanel.js';
import AboutPanel from './components/ui/staticPages/about.js';
import PrivacyPanel from './components/ui/staticPages/privacy.js';
import TacPanel from './components/ui/staticPages/termsandconditions.js';
import InvestorsPanel from './components/ui/staticPages/investorsPanel.js';
import LargePieChart from './components/ui/menus/leftSubMenuComponents/largePieChart.js';
import ControlsNav from './components/ui/modals/controlsNavModal.js';

// auth
import Login from './components/dataManagement/login.js';

import FileInterface from './fileInteraface.js';

import CreateAccountPopup from './components/ui/modals/createAccountPopup.js';

import {
  loading_timer_count,
  useGlobalRefs,
  useGlobalFileStates,
  useGlobalState,
  fmesh
} from './components/core/globalVariables.js';

import DraggableDrawer from './components/ui/mobileDrawers/draggableDrawer.js';
import DraggableSubDrawer from './components/ui/mobileDrawers/draggableSubDrawer.js';

const HomeApp = () => {

  const globalRefs = useGlobalRefs();
  const globalFileStates = useGlobalFileStates();
  const globalState = useGlobalState();

  let mapRef = globalRefs.mapRef;
  let drawRef = globalRefs.drawRef;
  let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
  let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  let mapContainerRef = globalRefs.mapContainerRef;
  let originalGeoJSONforComparingRef = globalRefs.originalGeoJSONforComparingRef;
  let mapboxTokenRef = globalRefs.mapboxTokenRef;
  let piechartRef = globalRefs.piechartRef;

  let isFilenameInputOpen = globalFileStates.isFilenameInputOpen;
  let setIsFilenameInputOpen = globalFileStates.setIsFilenameInputOpen;
  let newFilename = globalFileStates.newFilename;
  let setNewFilename = globalFileStates.setNewFilename;
  let setNewFileScreenShot = globalFileStates.setNewFileScreenShot;
  let loadedFileName = globalFileStates.loadedFileName;

  let clickedInfo = globalState.clickedInfo;
  let clickedInfoHeader = globalState.clickedInfoHeader;
  let clickedInfoHeaderMobile = globalState.clickedInfoHeaderMobile;
  let isVisible = globalState.isVisible;
  let isDrawing = globalState.isDrawing;
  let setisDrawing = globalState.setisDrawing;
  let isLoading = globalState.isLoading;
  let setIsLoading = globalState.setIsLoading;
  let filenames = globalState.filenames;
  let isMenuLoadClick = globalState.isMenuLoadClick;
  let setisMenuLoadClick = globalState.setisMenuLoadClick;
  let isHasSaved = globalState.isHasSaved;
  let isHasSavedCurrentData = globalState.isHasSavedCurrentData;
  let openAuth = globalState.openAuth;
  let setopenAuth = globalState.setopenAuth;
  let isSelectingLandtypes = globalState.isSelectingLandtypes;
  let setisSelectingLandtypes = globalState.setisSelectingLandtypes;
  let isLeftDrawerOpen = globalState.isLeftDrawerOpen;
  let setisLeftDrawerOpen = globalState.setisLeftDrawerOpen;
  let isLeftSubDrawerOpen = globalState.isLeftSubDrawerOpen;
  let setisLeftSubDrawerOpen = globalState.setisLeftSubDrawerOpen;
  let selectedLandTypes = globalState.selectedLandTypes;
  let setSelectedLandTypes = globalState.setSelectedLandTypes;
  let selectedMetric = globalState.selectedMetric;
  let setSelectedMetric = globalState.setSelectedMetric;
  let isViewingPieChart = globalState.isViewingPieChart;
  let setisViewingPieChart = globalState.setisViewingPieChart;
  let isViewingForecast = globalState.isViewingForecast;
  let setisViewingForecast = globalState.setisViewingForecast;
  let selectedYearEditing = globalState.selectedYearEditing;
  let setSelectedYearEditing = globalState.setSelectedYearEditing;
  let isModifying = globalState.isModifying;
  let setisModifying = globalState.setisModifying;
  let isComparing = globalState.isComparing;
  let setisComparing = globalState.setisComparing;
  let isInSettings = globalState.isInSettings;
  let setIsInSettings = globalState.setIsInSettings;
  let isInAboutPanel = globalState.isInAboutPanel;
  let setIsInAboutPanel = globalState.setIsInAboutPanel;
  let isInPrivacyPanel = globalState.isInPrivacyPanel;
  let setIsInPrivacyPanel = globalState.setIsInPrivacyPanel;
  let isInTacPanel = globalState.isInTacPanel;
  let setIsInTacPanel = globalState.setIsInTacPanel;
  let isInInvestorsPanel = globalState.isInInvestorsPanel;
  let setIsInInvestorsPanel = globalState.setIsInInvestorsPanel;
  let dropdownhighlightedIndex = globalState.dropdownhighlightedIndex;
  let setdropdownhighlightedIndex = globalState.setdropdownhighlightedIndex;
  let is2DViewActive = globalState.is2DViewActive;
  let setIs2DViewActive = globalState.setIs2DViewActive;
  let uniqueTextureNamesArray = globalState.uniqueTextureNamesArray;
  let mapbearing = globalState.mapbearing;
  let setMapBearing = globalState.setMapBearing;
  let isLanduse = globalState.isLanduse;
  let setIsLanduse = globalState.setIsLanduse;
  let isViewingLargePieChart = globalState.isViewingLargePieChart;
  let setisViewingLargePieChart = globalState.setisViewingLargePieChart;
  let comparingLandtypeSelectedOption = globalState.comparingLandtypeSelectedOption;
  let setcomparingLandtypeSelectedOption = globalState.setcomparingLandtypeSelectedOption;
  let isDraggableSubDrawerOpen = globalState.isDraggableSubDrawerOpen;
  let setisDraggableSubDrawerOpen = globalState.setisDraggableSubDrawerOpen;
  let storedfeaturecurrentlandtype = globalState.storedfeaturecurrentlandtype;
  let setStoredFeatureCurrentLandtype = globalState.setStoredFeatureCurrentLandtype;
  let setIsCtrlDown = globalState.setIsCtrlDown;
  let loadedAmount = globalState.loadedAmount;
  let setLoadedAmount = globalState.setLoadedAmount;
  let isControlsNavOpen = globalState.isControlsNavOpen;
  let setIsControlsNavOpen = globalState.setIsControlsNavOpen;
  let foundFeature = globalState.foundFeature;

  let loggedInToAPI = globalState.loggedInToAPI;
  let setLoggedInToAPI = globalState.setLoggedInToAPI;

  let adminUser = globalState.adminUser;
  let setAdminUser = globalState.setAdminUser;

  let mapLoaded = globalState.mapLoaded;
  let setMapLoaded = globalState.setMapLoaded;

  let loadedPercentage = globalState.loadedPercentage;
  let setLoadedPercentage = globalState.setLoadedPercentage;

  let temporaryGeoJSONIntendedForSaving = globalState.temporaryGeoJSONIntendedForSaving;
  let setTemporaryGeoJSONIntendedForSaving = globalState.setTemporaryGeoJSONIntendedForSaving;

  let finishedEditing = globalState.finishedEditing;
  let setFinishedEditing = globalState.setFinishedEditing;

  let convertingData = globalState.convertingData;

  // - - - - -

  let universalParcelDifferentiator = globalState.universalParcelDifferentiator;
  let universalColorPaletteName = globalState.universalColorPaletteName;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(universalParcelDifferentiator)
  }
    , [universalParcelDifferentiator])

  useEffect(() => {
    const getQueryParams = () => {
      if(location.search === '?login') {
        console.log("go to login")
        window.location.href = 'https://harrierapi9697.site/login'; // Point to your server's login endpoint
      }
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      if (token) {
        localStorage.setItem('token', token);
        navigate(location.pathname, { replace: true });
      }
    };
    getQueryParams();
  }, [location.search, navigate]);

  // - - - - -

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        setIsCtrlDown(true);
        polygonCustomLayerRef.current.controlDown();
      }
    };
    const handleKeyUp = (event) => {
      if (!event.ctrlKey) {
        setIsCtrlDown(false);
        polygonCustomLayerRef.current.controlUp();
      }
    };
    if (mapLoaded) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [mapLoaded]);

  // Function to open the filename input modal

  const openFilenameInputModal = () => {
    setIsFilenameInputOpen(true);
  };

  // Function to close the filename input modal

  const closeFilenameInputModal = () => {
    setIsFilenameInputOpen(false);
    setNewFilename('');
  };

  // Function to handle filename input change

  const handleFilenameInputChange = (e) => {
    setNewFilename(e.target.value);
  };

  // Adjust the click handler to also pass the base64image

  const saveDataWithFilenameClick = () => {
    setIsLoading(true);
    html2canvas(mapContainerRef.current, { scale: 1 }).then((canvas) => {
      const resizedCanvas = document.createElement('canvas');
      const ctx = resizedCanvas.getContext('2d');
      resizedCanvas.width = 300;
      resizedCanvas.height = 300;
      ctx.drawImage(canvas, 0, 0, 300, 300);
      const base64image = resizedCanvas.toDataURL("image/png");
      setNewFileScreenShot(base64image);
      saveDataWithFilename(globalRefs, globalFileStates, globalState, base64image);
    });
  };

  // Save current data to server

  const saveCurrentDataSetClick = () => {
    setIsLoading(true);
    html2canvas(mapContainerRef.current, { scale: 1 }).then((canvas) => {
      const resizedCanvas = document.createElement('canvas');
      const ctx = resizedCanvas.getContext('2d');
      resizedCanvas.width = 300;
      resizedCanvas.height = 300;
      ctx.drawImage(canvas, 0, 0, 300, 300);
      const base64image = resizedCanvas.toDataURL("image/png");
      setNewFileScreenShot(base64image);
      saveCurrentDataSet(globalRefs, globalFileStates, globalState, base64image);
    });
  };

  // Load data from server

  const closeLoadMenu = () => {
    setisMenuLoadClick(false);
  }

  // Load data from server

  const loadMenuClick = () => {
    setisMenuLoadClick((prevIsMenuLoadClick) => !prevIsMenuLoadClick);
  }

  // Auth clicked

  const authClicked = () => {
    setopenAuth(true);
  };

  // Close auth

  const closeAuth = () => {
    setopenAuth(false);
  }

  const closeControlsNav = () => {
    setIsControlsNavOpen(false);
  }

  // Landtype selection cancel on click

  const LandtypeSelectionCancelOnClick = () => {
    if (isSelectingLandtypes) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisSelectingLandtypes(false);
      setisLeftSubDrawerOpen(false);
    } else {
      if (isMobile) {
        resetLeftSubMenu();
        setisDraggableSubDrawerOpen(true);
        setisSelectingLandtypes(true);
      } else {
        resetLeftSubMenu();
        setisSelectingLandtypes(true);
        setisLeftSubDrawerOpen(true);
      }
    }
  }

  const PieChartselectionCancelOnClick = () => {
    if (isViewingPieChart) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisViewingPieChart(false);
      setisLeftSubDrawerOpen(false);
    } else {
      if (isMobile) {
        resetLeftSubMenu();
        setisDraggableSubDrawerOpen(true);
        setisViewingPieChart(true);
      } else {
        resetLeftSubMenu();
        setisViewingPieChart(true);
        setisLeftSubDrawerOpen(true);
        polygonCustomLayerRef.current.serEnablePieChart();
      }
    }
  }

  const handleEnlargePieButtonClick = () => {
    setisViewingLargePieChart(true);
    setisViewingPieChart(false);
    setisLeftSubDrawerOpen(false);
    polygonCustomLayerRef.current.resetfeatureOpacities(fmesh);
  }

  const handleClosePieButtonClick = () => {
    setisViewingLargePieChart(false);
    setisViewingPieChart(false);
    setisLeftSubDrawerOpen(false);
  }

  const ForecastCancelOnClick = () => {
    resetLeftSubMenu();
    setisViewingForecast(true);
    setisLeftSubDrawerOpen(true);
  }

  const ModifySelectionCancelOnClick = () => {
    if (isViewingForecast) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisViewingForecast(false);
      setisLeftSubDrawerOpen(false);
    } else {
      setisViewingForecast(true);
      resetLeftSubMenu();
      setisLeftSubDrawerOpen(true);
    }
  }

  const handleModifyClick = () => {
    if (isModifying) {
      setIs2DViewActive(false);
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisModifying(false);
      setisLeftSubDrawerOpen(false);
    } else {
      setIs2DViewActive(true);
      resetLeftSubMenu();
      setisModifying(true);
      beginDrawOnClick();
      setisLeftSubDrawerOpen(true);
    }
  }

  const handleCompareClick = () => {
    if (isComparing) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisComparing(false);
      setisLeftSubDrawerOpen(false);
    } else {
      resetLeftSubMenu();
      setisComparing(true);
      setisLeftSubDrawerOpen(true);
    }
  }

  const handleLandUseClick = () => {
    if (isLanduse) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setIsLanduse(false);
      setisLeftSubDrawerOpen(false);
    } else {
      resetLeftSubMenu();
      setIsLanduse(true);
      setisLeftSubDrawerOpen(true);
      polygonCustomLayerRef.current.setEnableLandUse();
    }
  }

  // Begin draw

  const beginDrawOnClick = () => {
    polygonCustomLayerRef.current.set2DViewBoundary();
    setIsLoading(true);
    setisDrawing(true);
    // setIs2DViewActive(true);
    setisLeftSubDrawerOpen(true);
    setTimeout(() => {
      initializeMapboxDraw(globalRefs, globalState);
      polygonCustomLayerRef.current.removeDrawFromScene();
    }, loading_timer_count);
  };

  // End draw and save

  const endDrawOnClick = () => {
    setIsLoading(true);
    setisDrawing(false);
    setisModifying(false);
    setIs2DViewActive(false);
    ResetMap(globalRefs, globalState);
    setisLeftSubDrawerOpen(false);
    setFinishedEditing(false);
  };

  // End draw and cancel

  const endDrawCancelOnClick = () => {
    setIsLoading(true);
    setIs2DViewActive(false);
    setisDrawing(false);
    setisModifying(false);
    ResetMap(globalRefs, globalState);
    setisLeftSubDrawerOpen(false);
    setFinishedEditing(false);
  };

  const handleLandtypePTagClick = (item) => {
    if (item === 'Reset') {
      polygonCustomLayerRef.current.setLandtype([]);
      setSelectedLandTypes([]);
      return;
    }
    setSelectedLandTypes(prevSelectedLandTypes => {
      if (prevSelectedLandTypes.includes(item)) {
        const newSelectedLandTypes = prevSelectedLandTypes.filter(type => type !== item);
        polygonCustomLayerRef.current.setLandtype(newSelectedLandTypes);
        return newSelectedLandTypes
      } else {
        const newSelectedLandTypes = [...prevSelectedLandTypes, item];
        polygonCustomLayerRef.current.setLandtype(newSelectedLandTypes);
        return newSelectedLandTypes;
      }
    });
  };

  // Reset left sub menu

  const resetLeftSubMenu = () => {
    setSelectedLandTypes([]);
    if (isDrawing) {
      setIsLoading(true);
      ResetMap(globalRefs, globalState);
      setisDrawing(false);
    }
    if (isSelectingLandtypes) {
      setisSelectingLandtypes(false);
      polygonCustomLayerRef.current.setLandtype([]);
      setSelectedLandTypes([]);
    }
    if (isViewingPieChart) {
      setisViewingPieChart(false);
    }
    if (piechartRef.current) {
      piechartRef.current.destroy();
    }
    if (isViewingForecast) {
      setisViewingForecast(false);
    }
    if (isModifying) {
      setisModifying(false);
    }
    if (isComparing) {
      setisComparing(false);
    }
    if (isLanduse) {
      setIsLanduse(false);
    }
    if (mapRef.current.hasControl(drawRef.current)) {
      mapRef.current.off('draw.create');
      mapRef.current.off('draw.delete');
      mapRef.current.removeControl(drawRef.current);
    }
    polygonCustomLayerRef.current.handleCloseClickedInfoCancel();
    polygonCustomLayerRef.current.setDisableLandUse();
    polygonCustomLayerRef.current.setDisablePieChart();
  }

  const toggleDrawer = () => {
    setisLeftDrawerOpen((prevState) => !prevState);
  }

  const toggleLeftSubDrawer = () => {
    setisLeftSubDrawerOpen((prevState) => !prevState);
    resetLeftSubMenu();
  }

  const resetMetric = () => {
    ResetMap(globalRefs, globalState);
  }

  const isStateUpdated = useRef(false);

  const closeSettingsPanel = () => {
    setIsInSettings(false);
  }

  const openAboutPanel = () => {
    window.location.href = 'https://harrier.land';
  }

  const openPrivacyPanel = () => {
    resetAboutPanels();
    setIsInPrivacyPanel(true);
  }

  const openTacPanel = () => {
    resetAboutPanels();
    setIsInTacPanel(true);
  }

  const openInverstorsPanel = () => {
    resetAboutPanels();
    setIsInInvestorsPanel(true);
  }

  const openMobileSignIn = () => {
    setopenAuth(true);
  }

  const resetAboutPanels = () => {
    setIsInSettings(false);
    setIsInAboutPanel(false);
    setIsInPrivacyPanel(false);
    setIsInTacPanel(false);
    setIsInInvestorsPanel(false);
  }

  // Initialize map
  const initializeMap = () => {
    mapboxgl.accessToken = mapboxTokenRef.current;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/jevonmahoney/clye79kxt00oz01pmchxfdgvq',
      center: [-0.9061603891426653, 54.121878725877096],
      zoom: 5,
      pitch: 40,
      bearing: 0,
      preserveDrawingBuffer: true
    });
    mapRef.current.on('load', () => {
      if (mapRef.current.isStyleLoaded()) {
        setMapLoaded(true);
        setLoadedPercentage(20);
      }
    });
    mapRef.current.on('rotate', () => {
      setMapBearing(mapRef.current.getBearing());
    });
    mapRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      })
    );
    if (isMobile) {
      setisLeftDrawerOpen(false);
    }
  };

  useEffect(() => {
    setLoadedPercentage(10);
    initializeMap();
  }, []);

  // 

  return (

    <div>

      <div className='top_menu'>
        <div className='title'>
          Logo
        </div>
        <div className='auth'>
          <MdAccountCircle />
        </div>
      </div>

      <MobileView>
        {isTablet ? (
          // If it's a tablet, render nothing
          <></>
        ) : (
          <>
            <DraggableSubDrawer
              isDraggableSubDrawerOpen={isDraggableSubDrawerOpen}
              setisDraggableSubDrawerOpen={setisDraggableSubDrawerOpen}
              isViewingPieChart={isViewingPieChart}
              longstandingGeoJSONRef={longstandingGeoJSONRef}
              selectedMetric={selectedMetric}
              handleLandtypePTagClick={handleLandtypePTagClick}
              polygonCustomLayerRef={polygonCustomLayerRef}
              piechartRef={piechartRef}
              handleEnlargePieButtonClick={handleEnlargePieButtonClick}
              isSelectingLandtypes={isSelectingLandtypes}
              selectedLandTypes={selectedLandTypes}
              uniqueTextureNamesArray={uniqueTextureNamesArray}
              selectedYearEditing={selectedYearEditing}
            />
            <DraggableDrawer
              PieChartselectionCancelOnClick={PieChartselectionCancelOnClick}
              LandtypeSelectionCancelOnClick={LandtypeSelectionCancelOnClick}
              ModifySelectionCancelOnClick={ModifySelectionCancelOnClick}
              handleCompareClick={handleCompareClick}
              handleLandUseClick={handleLandUseClick}
              isDraggableSubDrawerOpen={isDraggableSubDrawerOpen}
            />
          </>
        )}
      </MobileView>

      {convertingData ?
        <FileInterface
          globalRefs={globalRefs}
          globalState={globalState}
        /> : null}

      <LoadingSpinner
        isLoading={isLoading}
        loadedAmount={loadedAmount}
        setLoadedAmount={setLoadedAmount}
        setIsLoading={setIsLoading}
        loadedPercentage={loadedPercentage}
      />

      <TopMenu
        authClicked={authClicked}
        openAboutPanel={openAboutPanel}
        openPrivacyPanel={openPrivacyPanel}
        openTacPanel={openTacPanel}
        openInverstorsPanel={openInverstorsPanel}
        openMobileSignIn={openMobileSignIn}
        globalState={globalState}
      />

      <TopSubMenu
        openFilenameInputModal={openFilenameInputModal}
        saveCurrentDataSetClick={saveCurrentDataSetClick}
        loadMenuClick={loadMenuClick}
        isHasSaved={isHasSaved}
        isHasSavedCurrentData={isHasSavedCurrentData}
        loadedFileName={loadedFileName}
        isFilenameInputOpen={isFilenameInputOpen}
        closeFilenameInputModal={closeFilenameInputModal}
        newFilename={newFilename}
        handleFilenameInputChange={handleFilenameInputChange}
        saveDataWithFilenameClick={saveDataWithFilenameClick}
        isMenuLoadClick={isMenuLoadClick}
        closeLoadMenu={closeLoadMenu}
        filenames={filenames}
        mapboxTokenRef={mapboxTokenRef}
        globalRefs={globalRefs}
        globalState={globalState}
        setIsInAboutPanel={setIsInAboutPanel}
        setIsInInvestorsPanel={setIsInInvestorsPanel}
        setIsInPrivacyPanel={setIsInPrivacyPanel}
        setIsInTacPanel={setIsInTacPanel}
        isInAboutPanel={isInAboutPanel}
        isInInvestorsPanel={isInInvestorsPanel}
        isInPrivacyPanel={isInPrivacyPanel}
        isInTacPanel={isInTacPanel}
        resetAboutPanels={resetAboutPanels}
        isInSettings={isInSettings}
        mapbearing={mapbearing}
        loggedInToAPI={loggedInToAPI}
        universalParcelDifferentiator={universalParcelDifferentiator}
      />

      <LeftMenu
        isDrawing={isDrawing}
        isSelectingLandtypes={isSelectingLandtypes}
        LandtypeSelectionCancelOnClick={LandtypeSelectionCancelOnClick}
        setisSelectingLandtypes={setisSelectingLandtypes}
        beginDrawOnClick={beginDrawOnClick}
        endDrawOnClick={endDrawOnClick}
        endDrawCancelOnClick={endDrawCancelOnClick}
        toggleDrawer={toggleDrawer}
        isLeftDrawerOpen={isLeftDrawerOpen}
        isLeftSubDrawerOpen={isLeftSubDrawerOpen}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        resetMetric={resetMetric}
        PieChartselectionCancelOnClick={PieChartselectionCancelOnClick}
        ForecastCancelOnClick={ForecastCancelOnClick}
        ModifySelectionCancelOnClick={ModifySelectionCancelOnClick}
        selectedYearEditing={selectedYearEditing}
        setSelectedYearEditing={setSelectedYearEditing}
        isViewingPieChart={isViewingPieChart}
        isViewingForecast={isViewingForecast}
        isModifying={isModifying}
        handleModifyClick={handleModifyClick}
        isLanduse={isLanduse}
        handleLandUseClick={handleLandUseClick}
        isComparing={isComparing}
        handleCompareClick={handleCompareClick}
        globalState={globalState}
        globalRefs={globalRefs}
        loggedInToAPI={loggedInToAPI}
        ResetMap={ResetMap}
        longstandingGeoJSONRef={longstandingGeoJSONRef}
      />

      <LeftSubMenu
        isDrawing={isDrawing}
        beginDrawOnClick={beginDrawOnClick}
        endDrawOnClick={endDrawOnClick}
        endDrawCancelOnClick={endDrawCancelOnClick}
        isSelectingLandtypes={isSelectingLandtypes}
        toggleLeftSubDrawer={toggleLeftSubDrawer}
        isLeftSubDrawerOpen={isLeftSubDrawerOpen}
        handleLandtypePTagClick={handleLandtypePTagClick}
        selectedLandTypes={selectedLandTypes}
        isViewingPieChart={isViewingPieChart}
        longstandingGeoJSONRef={longstandingGeoJSONRef}
        originalGeoJSONforComparingRef={originalGeoJSONforComparingRef}
        isViewingForecast={isViewingForecast}
        selectedYearEditing={selectedYearEditing}
        setSelectedYearEditing={setSelectedYearEditing}
        ModifySelectionCancelOnClick={ModifySelectionCancelOnClick}
        isModifying={isModifying}
        isComparing={isComparing}
        handleModifyClick={handleModifyClick}
        handleCompareClick={handleCompareClick}
        selectedMetric={selectedMetric}
        uniqueTextureNamesArray={uniqueTextureNamesArray}
        handleLandUseClick={handleLandUseClick}
        isLanduse={isLanduse}
        polygonCustomLayerRef={polygonCustomLayerRef}
        isVisible={isVisible}
        dropdownhighlightedIndex={dropdownhighlightedIndex}
        setdropdownhighlightedIndex={setdropdownhighlightedIndex}
        setIsLanduse={setIsLanduse}
        piechartRef={piechartRef}
        handleEnlargePieButtonClick={handleEnlargePieButtonClick}
        setcomparingLandtypeSelectedOption={setcomparingLandtypeSelectedOption}
        resetLeftSubMenu={resetLeftSubMenu}
        setisLeftSubDrawerOpen={setisLeftSubDrawerOpen}
        storedfeaturecurrentlandtype={storedfeaturecurrentlandtype}
        setStoredFeatureCurrentLandtype={setStoredFeatureCurrentLandtype}
        temporaryGeoJSONIntendedForSaving={temporaryGeoJSONIntendedForSaving}
        setTemporaryGeoJSONIntendedForSaving={setTemporaryGeoJSONIntendedForSaving}
        setFinishedEditing={setFinishedEditing}
        finishedEditing={finishedEditing}
        universalParcelDifferentiator={universalParcelDifferentiator}
        universalColorPaletteName={universalColorPaletteName}
      />

      <Login
        openAuth={openAuth}
        closeAuth={closeAuth}
        setIsInSettings={setIsInSettings}
        isInSettings={isInSettings}
        isInAboutPanel={isInAboutPanel}
        isInInvestorsPanel={isInInvestorsPanel}
        isInPrivacyPanel={isInPrivacyPanel}
        isInTacPanel={isInTacPanel}
        setIsInAboutPanel={setIsInAboutPanel}
        setIsInInvestorsPanel={setIsInInvestorsPanel}
        setIsInPrivacyPanel={setIsInPrivacyPanel}
        setIsInTacPanel={setIsInTacPanel}
        loggedInToAPI={loggedInToAPI}
        setLoggedInToAPI={setLoggedInToAPI}
        globalState={globalState}
      />

      <ClickedInfo
        isVisible={isVisible}
        polygonCustomLayerRef={polygonCustomLayerRef}
        clickedInfo={clickedInfo}
        clickedInfoHeader={clickedInfoHeader}
        clickedInfoHeaderMobile={clickedInfoHeaderMobile}
        globalState={globalState}
        foundFeature={foundFeature}
        longstandingGeoJSONRef={longstandingGeoJSONRef}
      />

      {isComparing ? (
        <ComparePanel
          longstandingGeoJSONRef={longstandingGeoJSONRef}
          originalGeoJSONforComparingRef={originalGeoJSONforComparingRef}
          selectedMetric={selectedMetric}
          comparingLandtypeSelectedOption={comparingLandtypeSelectedOption}
          selectedYearEditing={selectedYearEditing}
          universalParcelDifferentiator={universalParcelDifferentiator}
        />
      ) : (
        <div>
          <GeneralNav
            globalRefs={globalRefs}
            globalState={globalState}
            selectedYearEditing={selectedYearEditing}
          />
          <BrowserView>
            {/* <ControlsNav
              globalRefs={globalRefs}
              globalState={globalState}
              selectedYearEditing={selectedYearEditing}
              closeControlsNav={closeControlsNav}
              isControlsNavOpen={isControlsNavOpen}
              setIsControlsNavOpen={setIsControlsNavOpen}
            /> */}
          </BrowserView>
        </div>
      )}

      {isViewingLargePieChart && !isComparing ? (
        <LargePieChart
          geojson={longstandingGeoJSONRef.current}
          isViewingPieChart={isViewingPieChart}
          selectedMetric={selectedMetric}
          polygonCustomLayerRef={polygonCustomLayerRef}
          piechartRef={piechartRef}
          handleEnlargePieButtonClick={handleEnlargePieButtonClick}
          handleClosePieButtonClick={handleClosePieButtonClick}
          selectedYearEditing={selectedYearEditing}
        />
      ) : (
        null
      )}

      <FilenameInputModal
        isFilenameInputOpen={isFilenameInputOpen}
        closeFilenameInputModal={closeFilenameInputModal}
        newFilename={newFilename}
        handleFilenameInputChange={handleFilenameInputChange}
        saveDataWithFilenameClick={saveDataWithFilenameClick}
        globalState={globalState}
        globalFileStates={globalFileStates}
        globalRefs={globalRefs}
        ResetMap={ResetMap}
      />

      {isInSettings ? (
        <SettingsPanel
          isComparing={isComparing}
          handlesettingsClose={closeSettingsPanel}
          longstandingGeoJSONRef={longstandingGeoJSONRef}
          originalGeoJSONforComparingRef={originalGeoJSONforComparingRef}
          selectedMetric={selectedMetric}
        />
      ) : (
        null
      )}

      {isInAboutPanel ? (
        <AboutPanel
        />
      ) : (
        null
      )}

      {isInPrivacyPanel ? (
        <PrivacyPanel
        />
      ) : (
        null
      )}

      {isInTacPanel ? (
        <TacPanel
        />
      ) : (
        null
      )}

      {isInInvestorsPanel ? (
        <InvestorsPanel
        />
      ) : (
        null
      )}

      <LoadMenu
        isMenuLoadClick={isMenuLoadClick}
        closeLoadMenu={closeLoadMenu}
        files={filenames}
        globalRefs={globalRefs}
        globalState={globalState}
        mapLoaded={mapLoaded}
        loggedInToAPI={loggedInToAPI}
        setLoggedInToAPI={setLoggedInToAPI}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        setLoadedPercentage={setLoadedPercentage}
      />

      <CreateAccountPopup
        globalRefs={globalRefs}
        globalState={globalState}
      />

      <div ref={mapContainerRef} id='mapContainerRef' className='map-container'></div>

    </div>

  );

};

export default HomeApp;
