
export const srcProjections = [
    { code: '', name: 'Select Coordinate System' },
    { code: 'EPSG:27700', name: 'British National Grid' },
    { code: 'EPSG:4326', name: 'WGS 84' },
    { code: 'EPSG:3857', name: 'Web Mercator' },
    { code: 'EPSG:32630', name: 'WGS 84 / UTM zone 30N' },
    { code: 'EPSG:32631', name: 'WGS 84 / UTM zone 31N' },
    { code: 'EPSG:32632', name: 'WGS 84 / UTM zone 32N' },
    { code: 'EPSG:32633', name: 'WGS 84 / UTM zone 33N' },
    { code: 'EPSG:32634', name: 'WGS 84 / UTM zone 34N' },
    { code: 'EPSG:32635', name: 'WGS 84 / UTM zone 35N' },
    { code: 'EPSG:32636', name: 'WGS 84 / UTM zone 36N' },
    { code: 'EPSG:32637', name: 'WGS 84 / UTM zone 37N' },
    { code: 'EPSG:32638', name: 'WGS 84 / UTM zone 38N' },
    { code: 'EPSG:32639', name: 'WGS 84 / UTM zone 39N' }
];

export const reProjections = [
    { code: 'EPSG:27700', name: 'British National Grid' },
    { code: 'EPSG:4326', name: 'WGS 84' },
    { code: 'EPSG:3857', name: 'Web Mercator' },
    { code: 'EPSG:32630', name: 'WGS 84 / UTM zone 30N' },
    { code: 'EPSG:32631', name: 'WGS 84 / UTM zone 31N' },
    { code: 'EPSG:32632', name: 'WGS 84 / UTM zone 32N' },
    { code: 'EPSG:32633', name: 'WGS 84 / UTM zone 33N' },
    { code: 'EPSG:32634', name: 'WGS 84 / UTM zone 34N' },
    { code: 'EPSG:32635', name: 'WGS 84 / UTM zone 35N' },
    { code: 'EPSG:32636', name: 'WGS 84 / UTM zone 36N' },
    { code: 'EPSG:32637', name: 'WGS 84 / UTM zone 37N' },
    { code: 'EPSG:32638', name: 'WGS 84 / UTM zone 38N' },
    { code: 'EPSG:32639', name: 'WGS 84 / UTM zone 39N' }
];

export const destProjections = [
    { code: 'EPSG:4326', name: 'WGS 84' },
    { code: 'EPSG:3857', name: 'Web Mercator' },
    { code: 'EPSG:27700', name: 'British National Grid' },
    { code: 'EPSG:32630', name: 'WGS 84 / UTM zone 30N' },
    { code: 'EPSG:32631', name: 'WGS 84 / UTM zone 31N' },
    { code: 'EPSG:32632', name: 'WGS 84 / UTM zone 32N' },
    { code: 'EPSG:32633', name: 'WGS 84 / UTM zone 33N' },
    { code: 'EPSG:32634', name: 'WGS 84 / UTM zone 34N' },
    { code: 'EPSG:32635', name: 'WGS 84 / UTM zone 35N' },
    { code: 'EPSG:32636', name: 'WGS 84 / UTM zone 36N' },
    { code: 'EPSG:32637', name: 'WGS 84 / UTM zone 37N' },
    { code: 'EPSG:32638', name: 'WGS 84 / UTM zone 38N' },
    { code: 'EPSG:32639', name: 'WGS 84 / UTM zone 39N' }
];
