import React from 'react';
import { colorPaletteOptions } from '../../../core/globalVariables.js';

const RenderedPTags = ({
  selectedLandTypes,
  handleLandtypePTagClick,
  universalParcelDifferentiator,
  selectedYearEditing,
  longstandingGeoJSONRef,
  universalColorPaletteName
}) => {
  // Create a color mapping for unique land types
  let geojson = longstandingGeoJSONRef.current;

  const getLandTypeColorMap = () => {
    const differentiatorColorMap = {};
    const uniqueDifferentiators = new Set(
      geojson.features
        .map(feature => feature.feature_data_simple_timeseries[universalParcelDifferentiator]?.[selectedYearEditing])
        .filter(value => value !== undefined)
    );

    console.log('uniqueDifferentiators', uniqueDifferentiators);

    // Define a color palette based on the options available
    const colorPalette = colorPaletteOptions[universalColorPaletteName]; // Change 'default' to your active palette name if necessary
    let colorIndex = 0;

    uniqueDifferentiators.forEach(differentiator => {
      differentiatorColorMap[differentiator] = colorPalette[colorIndex % colorPalette.length];
      colorIndex++;
    });

    return differentiatorColorMap;
  };

  const differentiatorColorMap = getLandTypeColorMap();

  console.log(differentiatorColorMap);

  const reset = 'Reset';
  const hideall = 'Hide all';

  const handleLandtypePTagClickHide = () => {
    handleLandtypePTagClick(reset);
    handleLandtypePTagClick(hideall);
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  return (
    <>
      <div className="ResetLandtypeListButton" onClick={() => handleLandtypePTagClick(reset)}>Reset</div>
      <div className="HideAllLandtypeListButton" onClick={handleLandtypePTagClickHide}>Hide all</div>
      <div className="land_types_accum_landkey">
        {Object.keys(differentiatorColorMap).map((item) => { // Use Object.keys() here
          const isSelected = selectedLandTypes.includes(item);

          // Use the color from the differentiatorColorMap
          const backgroundColor = differentiatorColorMap[item] || 'transparent'; // Fallback to 'transparent' if no color is found

          const truncatedItem = item.length > 30 ? `${item.substring(0, 30)}...` : item; // Truncate item at 30 characters
          const capitalizedTruncatedItem = capitalizeWords(truncatedItem); // Capitalize each word
          const imageSource = `${process.env.PUBLIC_URL}/ukhab/${item}.png`;

          return (
            <div key={item} className={`land_type_list_general_p ${isSelected ? 'selectedLandType' : ''}`} onClick={() => handleLandtypePTagClick(item)}>
              <span className='color_circle' style={{ backgroundColor }}></span>
              <div className="land_type_item_text">
                <p className="land_type_item_key">{capitalizedTruncatedItem}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RenderedPTags;
