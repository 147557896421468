import React, { useState, useEffect, useCallback } from 'react';
import * as turf from '@turf/turf';
import proj4 from 'proj4';
import { useGlobalRefsConverting, useGlobalStateConverting } from './components/core/globalVariablesConverting.js';
import DisplayDataConvert from './components/ui/displayDataConvert.js';
import InitializeMap from './components/core/mapboxInit.js';
import { srcProjections, destProjections } from './components/core/projections.js';
import MetricSelection from './MetricSelection.js';
import { parseShp, parseDbf } from 'shpjs';

// Define projections
proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs");
proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
proj4.defs("EPSG:3857", "+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +a=6378137 +b=6378137 +units=m +no_defs");
proj4.defs("EPSG:32630", "+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32631", "+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32632", "+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32633", "+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32634", "+proj=utm +zone=34 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32635", "+proj=utm +zone=35 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32636", "+proj=utm +zone=36 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32637", "+proj=utm +zone=37 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32638", "+proj=utm +zone=38 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32639", "+proj=utm +zone=39 +datum=WGS84 +units=m +no_defs");

const FileToGeoJSONConversion = ({
    data,
    filename,
    globalRefs,
    globalState,
    fileType,
}) => {

    const globalRefsConverting = useGlobalRefsConverting();
    const globalStateConverting = useGlobalStateConverting();
    let mapLoadedData = globalStateConverting.mapLoadedData;
    let setMapLoadedData = globalStateConverting.setMapLoadedData;

    const [jsonData, setJsonData] = useState(null);
    const [geoJsonData, setGeoJsonData] = useState(null);
    const [srcProj, setSrcProj] = useState(srcProjections[0].code);
    const [destProj, setDestProj] = useState(destProjections[0].code);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showdata, setshowdata] = useState(false);
    const [userConverting, setuserConverting] = useState(true);

    useEffect(() => {
        if (data) {
            console.log(data)
            setJsonData(data);
            setDataLoaded(true);
        }
    }, [data]);

    const triggerDownload = (geoJsonData) => {
        const dataStr = JSON.stringify(geoJsonData);
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'reprojected_data.geojson'; // Filename for download
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // Clean up the URL
    };

    const changeSrcAndProject = () => {
        return (e) => {
            setSrcProj(e.target.value);
            if (e.target.value === '') {
                return
            } else {
                if (fileType === 'geojson' || fileType === 'json') {
                    reprojectFromJSONToGeoJSON();
                }
                if (fileType === 'shp') {
                    reprojectFromShpToGeoJSON();
                }
                if (fileType === 'csv') {
                    reprojectFromCSVToGeoJSON();
                }
                if (fileType === 'xlsx') {
                    reprojectFromXLSXToGeoJSON();
                }
            }
        };
    }

    const reprojectFromCSVToGeoJSON = () => {
        const csvData = jsonData; // Assuming jsonData is the input CSV data converted to JSON
        // Swap the lat and lon in the GeoJSON data
        console.log("Before swapping:", csvData);
        csvData.features.forEach((feature) => {
            // Assuming the geometry is a Polygon and coordinates are structured as expected
            feature.geometry.coordinates = feature.geometry.coordinates.map(ring => {
                // Swap the coordinates: [lat, lon] -> [lon, lat]
                const swappedCoords = ring.map(coord => [coord[1], coord[0]]);
                // Check if the last coordinates match the first
                if (swappedCoords.length > 0) {
                    const firstCoord = swappedCoords[0];
                    const lastCoord = swappedCoords[swappedCoords.length - 1];

                    // If the last coordinate does not match the first, add the first coordinate to the end
                    if (firstCoord[0] !== lastCoord[0] || firstCoord[1] !== lastCoord[1]) {
                        swappedCoords.push(firstCoord);
                    }
                }
                // Ensure there are at least three points for a valid polygon
                if (swappedCoords.length < 3) {
                    console.error("Invalid polygon: Not enough points");
                    return []; // or handle it as needed
                }

                return swappedCoords;
            });
        });
        // Clean up any features that have invalid coordinates
        csvData.features = csvData.features.filter(feature =>
            feature.geometry.coordinates.length > 0 && feature.geometry.coordinates[0].length > 2
        );
        console.log("After swapping:", csvData);
        setGeoJsonData(csvData); // Set the modified GeoJSON data
    };

    const reprojectFromXLSXToGeoJSON = () => {
        const xlsxData = jsonData; // Assuming jsonData is the input XLSX data converted to JSON
        // Swap the lat and lon in the GeoJSON data
        console.log("Before swapping:", xlsxData);
        xlsxData.features.forEach((feature) => {
            // Assuming the geometry is a Polygon and coordinates are structured as expected
            feature.geometry.coordinates = feature.geometry.coordinates.map(ring => {
                // Swap the coordinates: [lat, lon] -> [lon, lat]
                const swappedCoords = ring.map(coord => [coord[1], coord[0]]);
                // Check if the last coordinates match the first
                if (swappedCoords.length > 0) {
                    const firstCoord = swappedCoords[0];
                    const lastCoord = swappedCoords[swappedCoords.length - 1];

                    // If the last coordinate does not match the first, add the first coordinate to the end
                    if (firstCoord[0] !== lastCoord[0] || firstCoord[1] !== lastCoord[1]) {
                        swappedCoords.push(firstCoord);
                    }
                }
                // Ensure there are at least three points for a valid polygon
                if (swappedCoords.length < 3) {
                    console.error("Invalid polygon: Not enough points");
                    return []; // or handle it as needed
                }

                return swappedCoords;
            });
        });
        // Clean up any features that have invalid coordinates
        xlsxData.features = xlsxData.features.filter(feature =>
            feature.geometry.coordinates.length > 0 && feature.geometry.coordinates[0].length > 2
        );
        console.log("After swapping:", xlsxData);
        setGeoJsonData(xlsxData); // Set the modified GeoJSON data
    };

    const reprojectFromJSONToGeoJSON = () => {
        function logCosineOfLat(coordinates) {
            return coordinates.map(coord => {
                const [lon, lat] = coord;
                const latRad = lat * (Math.PI / 180);
                const cosLat = Math.cos(latRad);
                return cosLat;
            });
        }
        function ensureValidLinearRing(ring) {
            if (ring.length < 4) {
                console.warn("Invalid LinearRing with fewer than 4 positions:", ring);
                return null;
            }
            const firstPosition = ring[0];
            const lastPosition = ring[ring.length - 1];
            if (firstPosition[0] !== lastPosition[0] || firstPosition[1] !== lastPosition[1]) {
                ring.push(firstPosition);
            }
            return ring;
        }
        if (jsonData && jsonData.features) {
            const features = jsonData.features.map(feature => {
                const geometry = feature.geometry;
                let polygon;
                if (geometry.rings) {
                    const validRings = geometry.rings.map(ensureValidLinearRing).filter(Boolean);
                    validRings.forEach(ring => logCosineOfLat(ring));
                    polygon = turf.polygon(validRings);
                } else if (geometry.type === "Polygon") {
                    const validRings = geometry.coordinates.map(ensureValidLinearRing).filter(Boolean);
                    validRings.forEach(ring => logCosineOfLat(ring));
                    polygon = turf.polygon(validRings);
                } else if (geometry.type === "MultiPolygon") {
                    const validPolygons = geometry.coordinates.map(polygonCoords => {
                        const validRings = polygonCoords.map(ensureValidLinearRing).filter(Boolean);
                        validRings.forEach(ring => logCosineOfLat(ring));
                        return validRings;
                    }).filter(Boolean);
                    polygon = turf.multiPolygon(validPolygons);
                } else if (geometry.type === "LineString" || geometry.type === "MultiLineString") {
                    const closedRings = geometry.coordinates.map(line => ensureValidLinearRing([...line, line[0]]));
                    closedRings.forEach(ring => logCosineOfLat(ring));
                    polygon = turf.polygon(closedRings.filter(Boolean));
                } else if (geometry.type === "Point" || geometry.type === "MultiPoint") {
                    logCosineOfLat(geometry.coordinates);
                    polygon = turf.buffer(turf.point(geometry.coordinates), 0.001, { units: 'kilometers' });
                } else {
                    console.warn(`Unsupported geometry type: ${geometry.type}`);
                    return null;
                }
                const { geometry: _, properties: __, ...otherKeys } = feature;
                return polygon ? {
                    type: "Feature",
                    geometry: polygon.geometry,
                    properties: feature.properties,
                    ...otherKeys
                } : null;
            }).filter(Boolean);
            if (features.length > 0) {
                const geoJson = {
                    type: "FeatureCollection",
                    features
                };
                setGeoJsonData(geoJson);
            } else {
                alert('No valid geometries available for conversion.');
            }
        } else {
            alert('No valid JSON data available for conversion.');
        }
    };

    const reprojectFromShpToGeoJSON = () => {
        const convertedGeoJson = {
            ...jsonData,
            features: jsonData.features.map((feature) => ({
                ...feature,
                geometry: {
                    ...feature.geometry,
                    coordinates: feature.geometry.coordinates,
                },
            })),
        };
        setGeoJsonData(convertedGeoJson);
    };

    useEffect(() => {
        if (geoJsonData) {
            reprojectGeoJson(geoJsonData);
        }
    }, [geoJsonData]);

    const reprojectGeoJson = (shiftedGeoJsonData) => {
        const transformCoordinates = (coords) => {
            return coords.map(coord => {
                const transformed = proj4(srcProj, destProj, coord);
                return transformed;
            });
        };
        const transformRings = (rings) => {
            return rings.map(ring => transformCoordinates(ring));
        };
        const transformedFeatures = shiftedGeoJsonData.features.map(feature => {
            const geometry = feature.geometry;
            const type = geometry.type;
            let transformedCoordinates;
            switch (type) {
                case 'Point':
                    transformedCoordinates = transformCoordinates([geometry.coordinates])[0];
                    break;
                case 'Polygon':
                    transformedCoordinates = transformRings(geometry.coordinates);
                    break;
                case 'MultiPolygon':
                    transformedCoordinates = geometry.coordinates.map(polygon => transformRings(polygon));
                    break;
                default:
                    console.warn('Unsupported geometry type:', type);
                    return feature;
            }
            const { geometry: _, properties: __, ...otherKeys } = feature;
            return {
                ...otherKeys,
                type: "Feature",
                geometry: { ...geometry, coordinates: transformedCoordinates },
                properties: feature.properties || {}
            };
        }).filter(Boolean);
        const reprojectedGeoJson = {
            type: "FeatureCollection",
            features: transformedFeatures
        };
        simplifyGeoJSON(reprojectedGeoJson);
    };

    const simplifyGeoJSON = (geoJsonData, tolerance = 0.0001, highQuality = false) => {
        // Calculate the total number of vertices in the GeoJSON
        const allCoordinates = turf.coordAll(geoJsonData);
        const vertexCount = allCoordinates.length;
        // Define a threshold for what constitutes "complex" geometry
        const complexityThreshold = 100; // Adjust based on your data
        // If the number of vertices exceeds the threshold, apply simplification
        if (vertexCount > complexityThreshold) {
            console.log(`Applying simplification. Vertex count: ${vertexCount}`);
            const geoJsonDataSimplified = turf.simplify(geoJsonData, {
                tolerance,   // Controls the simplification tolerance (higher value = more simplification)
                highQuality, // If true, slower but higher-quality simplification
            });
            setMapLoadedData(geoJsonDataSimplified);
        } else {
            // No simplification needed; directly set the original data
            console.log(`No simplification needed. Vertex count: ${vertexCount}`);
            setMapLoadedData(geoJsonData);
        }
    };

    useEffect(() => {
        if (mapLoadedData) {
            console.log("Map loaded data:", mapLoadedData);
            InitializeMap(globalRefsConverting, globalStateConverting);
        }
    }, [mapLoadedData]);

    const goToMetricAllocation = () => {
        setuserConverting(false);
    };

    return (
        <div className='carousel'>

            {userConverting ? (

                <div className="slide_01">

                    <div className='fileInterfacecontainer'>
                        <div className='leftColumn'>

                            <div className='coreInstructionBlockContainer'>
                                <div className="coreInstructionBlock">1. Upload</div>
                                <div className="coreInstructionBlockActive">2. Convert</div>
                                <div className="coreInstructionBlock">3. Metrics</div>
                            </div>

                            <h1>Convert</h1>

                            <div className="instructions">
                                <p>Please choose the Coordinate Reference System (CRS) that your data uses from the list below.</p>
                                <p>This will help ensure accurate mapping and analysis.</p>
                            </div>

                            {dataLoaded && (
                                <div>
                                    <div className='sourceprojectionSelector'>
                                        <select
                                            id="destProj"
                                            value={srcProj}
                                            onChange={
                                                changeSrcAndProject()
                                            }
                                        >
                                            {srcProjections.map(proj => (
                                                <option key={proj.code} value={proj.code}>
                                                    {proj.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {showdata && (
                                        <>
                                            <p>Loaded Data</p>
                                            <div className='loadedDataDisplay'>
                                                <pre>{JSON.stringify(jsonData, null, 2)}</pre>
                                            </div>
                                        </>
                                    )}

                                </div>
                            )}

                            {
                                geoJsonData && (
                                    <>
                                        {showdata && (
                                            <>
                                                <p>GeoJSON Data</p>
                                                <div className='convertedGeoJsonDisplay'>
                                                    <pre>{JSON.stringify(geoJsonData, null, 2)}</pre>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )
                            }

                            {
                                mapLoadedData && (
                                    <>
                                        {showdata && (
                                            <>
                                                <p>Reprojected Data</p>
                                                <div className='transformedGeoJsonDisplay'>
                                                    <pre>{JSON.stringify(mapLoadedData, null, 2)}</pre>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )
                            }
                            {/* 
                            <div className='convertGeoJsonButton'>
                                <button className='converterButton' onClick={reprojectFromJSONToGeoJSON}>Project</button>
                            </div> */}

                            {
                                mapLoadedData && (
                                    <div className='showConvertGeoJsonButton'>
                                        <button className='converterButton' onClick={() => goToMetricAllocation()}>
                                            Confirm
                                        </button>
                                    </div>
                                )
                            }

                        </div>

                        <div className="rightColumn">
                            <DisplayDataConvert
                                globalRefsConverting={globalRefsConverting}
                                globalStateConverting={globalStateConverting}
                            />
                        </div>
                    </div>
                </div>

            ) : (

                <div className="slide_01">

                    <MetricSelection
                        geojson={mapLoadedData}
                        globalRefs={globalRefs}
                        globalState={globalState}
                    />

                </div>

            )}

        </div>
    );

}

export default FileToGeoJSONConversion;
