import React from 'react';
import { presetColors } from '../../../../libs/colours.js';
import { foundFeature } from '../../../core/globalVariables.js';

const List = ({
  isVisible,
  polygonCustomLayerRef,
  selectedYearEditing,
  dropdownhighlightedIndex,
  setdropdownhighlightedIndex,
  uniqueTextureNamesArray,
  isLandUse,
  resetLeftSubMenu,
  setisLeftSubDrawerOpen,
  storedfeaturecurrentlandtype,
  longstandingGeoJSONRef,
  universalParcelDifferentiator
}) => {
  
  const handleItemClick = (index, landtype) => {
    polygonCustomLayerRef.current.handleCloseClickedInfo(landtype, selectedYearEditing);
    polygonCustomLayerRef.current.updateColor(foundFeature, landtype);
    setdropdownhighlightedIndex(dropdownhighlightedIndex !== index ? index : null);
  };

  const geojson = longstandingGeoJSONRef.current;

  // Create a Set of unique differentiators from the GeoJSON data
  const uniqueDifferentiators = new Set(
    geojson.features.map(feature =>
      feature.feature_data_simple_timeseries[universalParcelDifferentiator]?.[selectedYearEditing]
    )
  );

  // Convert the Set back to an array for mapping
  const differentiatorsArray = Array.from(uniqueDifferentiators);

  console.log("Unique Differentiators:", differentiatorsArray);

  const handleCloseClickedInfoCancel = () => {
    polygonCustomLayerRef.current.handleCloseClickedInfo(storedfeaturecurrentlandtype, selectedYearEditing);
    polygonCustomLayerRef.current.updateColor(foundFeature, storedfeaturecurrentlandtype);
    resetLeftSubMenu();
    setisLeftSubDrawerOpen(false);
  };

  const handleCloseClickedInfoButton = () => {
    polygonCustomLayerRef.current.handleCloseClickedInfoButton();
    resetLeftSubMenu();
    setisLeftSubDrawerOpen(false);
  };

  return (
    isLandUse && isVisible && (
      <div className='dropdown_list_outer'>
        <div className='dropdown_list'>
          <div className='list'>
            {differentiatorsArray.map((landtype, index) => {
              const imageSource = `${process.env.PUBLIC_URL}/ukhab/${landtype}.png`;
              const capitalisedKey = landtype.charAt(0).toUpperCase() + landtype.slice(1);
              const color = presetColors[landtype] || 'gray'; // Default color if not found

              return (
                <div
                  key={index}
                  onClick={() => handleItemClick(index, landtype)}
                  className={`itemblock ${dropdownhighlightedIndex === index ? 'highlighted' : ''}`}
                >
                  <span className='color_circle_landusechange' style={{ backgroundColor: color }}></span>
                  <div className='list_item'>
                    {capitalisedKey.length > 30 ? `${capitalisedKey.substring(0, 30)}...` : capitalisedKey}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className='clicked_info__close' onClick={handleCloseClickedInfoButton}>Confirm</div>
        <div className='clicked_info__close_cancel' onClick={handleCloseClickedInfoCancel}>Cancel</div>
      </div>
    )
  );
};

export default List;
