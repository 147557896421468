import supabaseClient from '../../supabaseClient';

async function saveDataWithFilename(globalRefs, globalFileStates, globalState, base64image) {

  let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  let newFilename = globalFileStates.newFilename;
  let setNewFilename = globalFileStates.setNewFilename;

  let setMapSavedWithFilenameSuccess = globalState.setMapSavedWithFilenameSuccess;
  let mapSavedWithFilenameSuccess = globalState.mapSavedWithFilenameSuccess;

  let mapId;

  let selectedYearEditing = globalState.selectedYearEditing;

  function generateUniqueId() {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
    for (let i = 0; i < 36; i++) {
      if (i === 8 || i === 13 || i === 18 || i === 23) {
        id += '-';
      } else {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
    }
    return id;
  }

  mapId = generateUniqueId();

  const token = localStorage.getItem('token');

  const response = await fetch(`https://harrierapi9697.site/api/maps?token=${encodeURIComponent(token)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: generateUniqueId(),
      title: newFilename,
      owner: {
        id: mapId,
        email: "test@skylark.land"
      },
      public: false,
      feature_header: {
        define_external_ids: {
          searchlight_property_id: {
            name: "Searchlight property ID",
            external_url: "https://foo.com/",
            description: "Identifies a property corresponding to this feature on Searchlight"
          },
          OBJECTID: {
            name: "Bar object ID",
            external_url: "https://bar.com/",
            description: "Something to do with system Bar"
          },
          OID_: {
            name: "Buzz object ID",
            external_url: "https://buzz.com/",
            description: "Something to do with system Buzz"
          },
          id: {
            name: "Ping feature ID",
            external_url: "https://ping.com/",
            description: "Something to do with system Ping"
          }
        },
        define_feature_data_simple: {
          OBJECTID: {
            name: "Bar object ID",
            type: "number",
            description: "Something to do with system Bar"
          },
          HabitatClass: {
            name: "Habitat Class",
            description: "TODO explain what this is. Might be best not to use magic numbers.",
            type: "number",
            enum: null,
            unit: null
          },
          Join_Count: {
            name: "Join Count",
            description: "TODO explain what this is.",
            type: "number",
            enum: null,
            unit: null
          },
          Comments: {
            name: "JComments",
            description: "TODO explain what this is.",
            type: "string",
            enum: null,
            unit: null
          },
          HabitatType: {
            name: "Habitat Type",
            description: "TODO explain what this is and where the valid options come from.",
            type: "string",
            enum: null,
            unit: null
          },
          HabClass: {
            name: "Habitat Type",
            description: "TODO explain what this is and where the valid options come from.",
            type: "string",
            enum: null,
            unit: null
          },
          Confidence: {
            name: "Confidence",
            description: "TODO explain how this is defined.",
            type: "string",
            enum: ["Low", "Medium", "High"],
            unit: null
          },
          Source: {
            name: "Source",
            description: "TODO what are the valid options and how is this defined.",
            type: "string",
            enum: ["Digitised", "MasterMap"],
            unit: null
          },
          LocalSignificance: {
            name: "Local Significance",
            description: "TODO explain what this is. Might be best not to use magic numbers.",
            type: "number",
            enum: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            unit: null
          },
          BDUnits_perHA: {
            name: "Biodiversity Units per hectare",
            description: "Biodiversity units per hectare measured using the Biodiversity Metric 3.0",
            type: "number",
            enum: null,
            unit: null
          },
          BDUnits_Parcel: {
            name: "Biodiversity Units",
            description: "Biodiversity units for this feature measured using the Biodiversity Metric 3.0 and derived from the per hectare value.",
            type: "number",
            enum: null,
            unit: null
          },
          Carbon_density_tC: {
            name: "Carbon Density",
            description: "Carbon Density for this feature measured in tons per hectare. TODO check if for the feature or per hectare.",
            type: "number",
            enum: null,
            unit: "tC/ha"
          },
          Carbon_flux_tCO2e: {
            name: "Carbon Flux",
            description: "Change in carbon measured in Tons of CO2 equivalent. TODO over what period/rate?",
            type: "number",
            enum: null,
            unit: "tCO2e/unit time period"
          },
          ParcelCarbon: {
            name: "Carbon Captured",
            description: "Carbon captured in this feature. TODO check if measure in tons of Carbon, CO2 equivalent or something else.",
            type: "number",
            enum: null,
            unit: "1000kg"
          },
          ParcelCarbonFlux: {
            name: "Carbon Flux",
            description: "Change in carbon captured in this feature. TODO check units including time period for rate.",
            type: "number",
            enum: null,
            unit: "1000kg/unit time period"
          },
          b_metric: {
            name: "B Metric",
            description: "TODO unknown.",
            type: "number",
            enum: null,
            unit: "TODO"
          },
          Value_GBP: {
            name: "Value in GBP",
            description: "Value of this feature in Great British Pounds",
            type: "number",
            enum: null,
            unit: "GBP"
          },
          ValuePSP: {
            name: "Value in PSP",
            description: "Value of this feature in PSP",
            type: "number",
            enum: null,
            unit: "PSP"
          },
          C_density_tC: {
            name: "Carbon Density",
            description: "Carbon Density for this feature measured in tons per hectare. TODO check if for the feature or per hectare.",
            type: "number",
            enum: null,
            unit: "tC/ha"
          },
          C_flux_tCO2e: {
            name: "Carbon Flux",
            description: "Change in carbon measured in Tons of CO2 equivalent. TODO over what period/rate?",
            type: "number",
            enum: null,
            unit: "tCO2e/unit time period"
          },
          osid: {
            name: "OSID",
            description: "Ordnance Survey ID",
            type: "string",
            enum: null,
            unit: null
          }
        },
        define_feature_data_simple_timeseries: {
          carbon_density: {
            name: "Carbon Density",
            description: "Carbon Density for this feature measured in tons per hectare. TODO check if for the feature or per hectare.",
            type: "number",
            enum: null,
            unit: "tC.ha-1"
          },
          HabitatType: {
            name: "HabitatType",
            description: "HabitatType changes by user over time using forecasting tools.",
            type: "string",
            enum: null,
            unit: null
          },
          BoundaryChange: {
            name: "BoundaryChange",
            description: "BoundaryChange changes by user over time using forecasting tools.",
            type: "string",
            enum: null,
            unit: null
          },
          ParcelCarbonFlux: {
            name: "ParcelCarbonFlux",
            description: "Fluctuation in carbon over time using forecasting tools.",
            type: "number",
            enum: null,
            unit: null
          },
          Value_GBP: {
            name: "Value_GBP",
            description: "Value over time in pounds GBP over time using forecasting tools.",
            type: "number",
            enum: null,
            unit: null
          },
          ValuePSP: {
            name: "ValuePSP",
            description: "Value over time in pounds GBP over time using forecasting tools.",
            type: "number",
            enum: null,
            unit: null
          },
          BDUnits_perHA: {
            name: "BDUnits_perHA",
            description: "BDUnits perHA over time in over time using forecasting tools.",
            type: "number",
            enum: null,
            unit: null
          },
          BDUnits_Parcel: {
            name: "BDUnits_Parcel",
            description: "Value over time in pounds GBP over time using forecasting tools.",
            type: "number",
            enum: null,
            unit: null
          },
          C_flux_tCO2e: {
            name: "C_flux_tCO2e",
            description: "BDUnits perHA over time in over time using forecasting tools.",
            type: "number",
            enum: null,
            unit: null
          },
          C_density_tC: {
            name: "C_density_tC",
            description: "BDUnits perHA over time in over time using forecasting tools.",
            type: "number",
            enum: null,
            unit: null
          },
          ParcelCarbon: {
            name: "ParcelCarbon",
            description: "BDUnits perHA over time in over time using forecasting tools.",
            type: "number",
            enum: null,
            unit: null
          },
          Shape_Area: {
            name: "Shape_Area",
            description: "BDUnits perHA over time in over time using forecasting tools.",
            type: "number",
            enum: null,
            unit: null
          }
        }
      }
    }),
    credentials: 'include', // Correct syntax for including credentials
  });
  const data = await response.json();

  let genMapId = data.id;

  if (response.ok) {

    let dataUpload = longstandingGeoJSONRef.current;

    for(let i = 0 ; i < dataUpload.features.length; i++){
     if(dataUpload.features[i].feature_data_simple_timeseries.BoundaryChange[selectedYearEditing] != "000,000"){
     }
    }

    // Create newDataArray with nested coordinates
    let newDataArray = [];
    let invalidFeatures = [];

    for (let i = 0; i < dataUpload.features.length; i++) {

      let feature = { ...dataUpload.features[i] };
      delete feature.type;
      delete feature.skylark_id;
      const coordinates = feature.geometry.coordinates[0]; // Assuming coordinates are already nested correctly

      // Check if the feature has fewer than 4 coordinate pairs
      if (coordinates.length < 4) {
        console.error(`Feature ${i} has an incorrect number of coordinate pairs:`, coordinates);
        invalidFeatures.push(feature);
      } else {
        // Otherwise, process the feature as usual
        newDataArray.push(feature);
      }
    }

    const srid = 4326;  // Replace with the appropriate SRID
    const token = localStorage.getItem('token');

    const response = await fetch(`https://harrierapi9697.site/api/maps/${genMapId}/features?&token=${encodeURIComponent(token)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newDataArray),
      credentials: 'include',
    });

    const result = await response.text();

    if (response.ok) {
      setMapSavedWithFilenameSuccess(true);

      try {
        const { data, error } = await supabaseClient
          .from('images')
          .insert([{ name: genMapId, base64: base64image }]);
        if (error) {
        } else {
        }
      } catch (error) {
      }

    } else {
      console.error('Error updating:', result);
    }

  }
}

export default saveDataWithFilename;
