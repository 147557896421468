import React from "react";
import LandTypes from './leftMenuComponents/landTypesButton.js';
import PieChart from './leftMenuComponents/pieChartButton.js';
import Modify from './leftMenuComponents/modifyButton.js';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { HiClipboardList } from "react-icons/hi";
import { RiPieChartFill } from "react-icons/ri";
import MetricDropdown from './leftMenuComponents/metricDropdown.js';
import DataDropdown from './leftMenuComponents/dataDropdown.js';
import YearDropdown from './leftMenuComponents/yearDropdown.js';
import LandUseButton from "./leftMenuComponents/landUseButton.js";
import CompareButton from "./leftMenuComponents/compareButton.js";
import { isMobile, MobileView, BrowserView, isTablet } from 'react-device-detect';

import { MdLandslide } from "react-icons/md";
import { PiScalesFill } from "react-icons/pi";
import { HiPencil } from "react-icons/hi";
import { Translate } from "@mui/icons-material";

const LeftMenu = ({
  isSelectingLandtypes,
  LandtypeSelectionCancelOnClick,
  toggleDrawer,
  isLeftDrawerOpen,
  isLeftSubDrawerOpen,
  selectedMetric,
  setSelectedMetric,
  resetMetric,
  PieChartselectionCancelOnClick,
  ModifySelectionCancelOnClick,
  selectedYearEditing,
  setSelectedYearEditing,
  isViewingPieChart,
  isViewingForecast,
  isModifying,
  handleModifyClick,
  isLanduse,
  handleLandUseClick,
  isComparing,
  handleCompareClick,
  globalState,
  globalRefs,
  loggedInToAPI,
  ResetMap,
  longstandingGeoJSONRef,
}) => {

  let setConvertingData = globalState.setConvertingData;

  let setIsCreateAccountPopupVisible = globalState.setIsCreateAccountPopupVisible;

  // initially check for data

  let noData = true;

  if (!longstandingGeoJSONRef.current) {
    noData = true;
  } else {
    noData = false;
  }

  const openConverterPortal = () => {
    setConvertingData(true);
  };

  // initially check for data end

  const mobileStatus = isMobile ? "mobile" : "not-mobile";

  return (
    <div className='leftMenuButton'>

      <MobileView>
        {isTablet ? (
          <button className={`togglerDrawInside not-mobile}`} onClick={toggleDrawer}>
            <IoIosArrowForward />
          </button>
        ) : (
          null
        )}
      </MobileView>

      <BrowserView>
        <button className={`togglerDrawInside ${mobileStatus}`} onClick={toggleDrawer}>
          <IoIosArrowForward />
        </button>
      </BrowserView>

      {/* Main Drawer */}
      <Drawer
        open={isLeftDrawerOpen}
        direction='left'
        className='leftMenu'
        enableOverlay={false}
        duration={150}
      >
        {/* Conditional Rendering based on noData */}
        {noData ? (
          <div className="noData">
            <div
              className="noDataText"
              style={{
                position: 'absolute',         // Added to ensure the element is positioned correctly
                left: '50%',
                padding: '20px',
                textAlign: 'center',
                transform: 'translateX(-50%)', // Corrected the `transform` property to use `translateX` for horizontal centering
                marginTop: '50px',
                // backgroundColor: '#ff0000',
                // marginLeft: '-20px',           // Changed `marginLeft` to a string to ensure proper rendering
                width: '70%',
                borderRadius: '10px',
                // border: '1px solid #000',
              }}
            >
              <h2 style={{ margin: '0px' }} >Hey there!</h2>
              <p>It looks like you haven't loaded any data yet!</p>
              <div className="leftMenuConverterButton" onClick={openConverterPortal}>Upload Data</div>
              <p>or</p><p>Press Load in the Menu above</p>
            </div>
          </div>
        ) : (
          <>
            {loggedInToAPI ? (
              null
            ) : (
              <>
                <h2>Choose Data</h2>
                <DataDropdown
                  resetMetric={resetMetric}
                  setSelectedMetric={setSelectedMetric}
                  selectedMetric={selectedMetric}
                  globalState={globalState}
                  ResetMap={ResetMap}
                  globalRefs={globalRefs}
                  selectedYearEditing={selectedYearEditing}
                />
              </>
            )}
            {/* Metric Selection */}
            <h2>Choose Metric</h2>
            <MetricDropdown
              resetMetric={resetMetric}
              setSelectedMetric={setSelectedMetric}
              selectedMetric={selectedMetric}
              globalState={globalState}
              ResetMap={ResetMap}
              globalRefs={globalRefs}
              selectedYearEditing={selectedYearEditing}
            />

            {/* Year Selection */}
            {/* <h2>Select Year</h2>
            <YearDropdown
              selectedMetric={selectedMetric}
              isModifying={isModifying}
              selectedYearEditing={selectedYearEditing}
              globalState={globalState}
              globalRefs={globalRefs}
              setSelectedYearEditing={setSelectedYearEditing}
              ResetMap={setSelectedYearEditing}
              setIsCreateAccountPopupVisible={setIsCreateAccountPopupVisible}
            /> */}

            {/* Land Types and Pie Chart */}
            {loggedInToAPI ? (
              <LandTypes
                LandtypeSelectionCancelOnClick={LandtypeSelectionCancelOnClick}
                isSelectingLandtypes={isSelectingLandtypes}
              />
            ) : (
              <div
                onMouseEnter={() => setIsCreateAccountPopupVisible(true)}
                onMouseLeave={() => setIsCreateAccountPopupVisible(false)}
                className={`land_types ${isSelectingLandtypes ? 'selected' : ''}`}
                style={{ opacity: 0.5 }}
              >
                <div disabled id='begin_draw_button'
                  onMouseEnter={() => setIsCreateAccountPopupVisible(true)}
                  onMouseLeave={() => setIsCreateAccountPopupVisible(false)}
                >
                  <HiClipboardList className="leftMenuIcons" /> Land Key
                </div>
              </div>
            )}

            {loggedInToAPI ? (
              <PieChart
                PieChartselectionCancelOnClick={PieChartselectionCancelOnClick}
                isViewingPieChart={isViewingPieChart}
              />
            ) : (
              <div
                onMouseEnter={() => setIsCreateAccountPopupVisible(true)}
                onMouseLeave={() => setIsCreateAccountPopupVisible(false)}
                className={`land_types ${isViewingPieChart ? 'selected' : ''}`} >
                <div disabled style={{ opacity: .5 }}
                  onMouseEnter={() => setIsCreateAccountPopupVisible(true)}
                  onMouseLeave={() => setIsCreateAccountPopupVisible(false)}
                >
                  <RiPieChartFill className="leftMenuIcons" /> Pie Chart</div>
              </div>
            )}

            {/* Modify Button */}
            {loggedInToAPI ? (
              <Modify
                ModifySelectionCancelOnClick={ModifySelectionCancelOnClick}
                isViewingForecast={isViewingForecast}
                handleModifyClick={handleModifyClick}
                isModifying={isModifying}
              />
            ) : (
              <div
                onMouseEnter={() => setIsCreateAccountPopupVisible(true)}
                onMouseLeave={() => setIsCreateAccountPopupVisible(false)}
                className={`land_types ${isModifying ? 'selected' : ''}`}>
                <div disabled style={{ opacity: .5 }}
                  onMouseEnter={() => setIsCreateAccountPopupVisible(true)}
                  onMouseLeave={() => setIsCreateAccountPopupVisible(false)}
                >
                  <HiPencil className="leftMenuIcons" /> Boundaries
                </div>
              </div>
            )}

            {/* Land Use Button */}
            {loggedInToAPI ? (
              // <LandUseButton
              //   handleLandUseClick={handleLandUseClick}
              //   isLanduse={isLanduse}
              // />
              <div className={`land_types ${isLanduse ? 'selected' : ''}`}>
                <div disabled style={{ opacity: .5 }}
                  onMouseEnter={() => setIsCreateAccountPopupVisible(true)}
                  onMouseLeave={() => setIsCreateAccountPopupVisible(false)}
                >
                  <MdLandslide className="leftMenuIcons" /> Land Use
                </div>
              </div>
            ) : (
              <div
                onMouseEnter={() => setIsCreateAccountPopupVisible(true)}
                onMouseLeave={() => setIsCreateAccountPopupVisible(false)}
                className={`land_types ${isLanduse ? 'selected' : ''}`}>
                <div disabled style={{ opacity: .5 }}
                  onMouseEnter={() => setIsCreateAccountPopupVisible(true)}
                  onMouseLeave={() => setIsCreateAccountPopupVisible(false)}
                >
                  <MdLandslide className="leftMenuIcons" /> Land Use
                </div>
              </div>
            )}

            {/* Compare Button */}
            {loggedInToAPI ? (
              // <CompareButton 
              //   handleCompareClick={handleCompareClick}
              //   isComparing={isComparing}
              // />
              <div className={`land_types ${isComparing ? 'selected' : ''}`}>
                <div disabled style={{ opacity: .5 }}>
                  <PiScalesFill className="leftMenuIcons" /> Compare
                </div>
              </div>
            ) : (
              <div
                onMouseEnter={() => setIsCreateAccountPopupVisible(true)}
                onMouseLeave={() => setIsCreateAccountPopupVisible(false)}
                className={`land_types ${isComparing ? 'selected' : ''}`}>
                <div disabled style={{ opacity: .5 }}>
                  <PiScalesFill className="leftMenuIcons" /> Compare
                </div>
              </div>
            )}
          </>
        )}

        {/* Drawer Close Button */}
        {!isLeftSubDrawerOpen && (
          <button className='togglerDrawOutside' onClick={toggleDrawer}>
            <IoIosArrowBack />
          </button>
        )}
      </Drawer>
    </div>
  );
};

export default LeftMenu;