import React from 'react';
import { presetColors } from '../../../../libs/colours';

const RenderedPTagsWithKey = ({
  selectedLandTypes,
  handleLandtypePTagClick,
  uniqueTextureNamesArray,
  setcomparingLandtypeSelectedOption
}) => {

  const items = uniqueTextureNamesArray;

  for(let i = 0; i < items.length; i++) {
    if (items[i] === 'undefined') {
      items.splice(i, 1);
    }
  }

  const reset = 'Reset';

  const comparingLandtypeSelected = (landtype) => {
    if (landtype === reset) {
      setcomparingLandtypeSelectedOption('none');
      return;
    }
    setcomparingLandtypeSelectedOption(landtype);
  };

  return (
    <>
      <div className="land_types_accum_compare">
        {items.map((item) => {
          const isSelected = selectedLandTypes.includes(item);
          const backgroundColor = presetColors[item] || 'transparent';

          const capitalizeWords = (str) => {
            return str.replace(/\b\w/g, char => char.toUpperCase());
          };

          const truncatedItem = item.length > 30 ? `${item.substring(0, 30)}...` : item; 
          const capitalizedTruncatedItem = capitalizeWords(truncatedItem);

          const imageSource = `${process.env.PUBLIC_URL}/ukhab/${item}.png`;
          return (
            <div
              key={item}
              onMouseMove={() => comparingLandtypeSelected(item)}
              onMouseOut={() => comparingLandtypeSelected(reset)}
              className={`land_type_list_general_p_compare ${isSelected ? 'selectedLandType' : ''}`}
            >
              <span className='color_circle' style={{ backgroundColor }}></span>
              <div className="land_type_item_text">
                <p className="land_type_item_key">{capitalizedTruncatedItem}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  )
};

export default RenderedPTagsWithKey;
