import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";

const Login = ({
    openAuth,
    closeAuth,
    setIsInSettings,
    isInSettings,
    isInAboutPanel,
    isInInvestorsPanel,
    isInPrivacyPanel,
    isInTacPanel,
    setIsInAboutPanel,
    setIsInInvestorsPanel,
    setIsInPrivacyPanel,
    setIsInTacPanel,
    loggedInToAPI,
    setLoggedInToAPI,
    globalState
}) => {
    const [email, setEmail] = useState("");
    const [user, setUser] = useState(null);
    const [forgotPasswordScreen, setForgotPasswordScreen] = useState(true);
    const [forgotPasswordEmailSent, setForgotPasswordEmailSent] = useState(true);
    const [incorrectPassword, setIncorrectPassword] = useState(false);

    let workingLocally = globalState.workingLocally;
    let userEmail = globalState.userEmail;

    const handleLogin = () => {
        if (workingLocally) {
            window.location.href = '/api/login';
        } else {
            window.location.href = 'https://harrierapi9697.site/login'; // Point to your server's login endpoint
        }
    };

    // const handleRegister = () => {
    //     if (workingLocally) {
    //         window.location.href = '/api/register';
    //     } else {
    //         window.location.href = 'https://harrierapi9697.site/register'; // Point to your server's register endpoint
    //     }
    // };

    const handleToggleForgotPasswordScreen = () => {
        setForgotPasswordScreen(!forgotPasswordScreen);
    };

    const handleSignOut = async () => {
        try {
            // await signOut(auth);
            console.log("signing out")
            setUser(null);
            setLoggedInToAPI(false);
            setIsInAboutPanel(false);
            setIsInInvestorsPanel(false);
            setIsInPrivacyPanel(false);
            setIsInTacPanel(false);
            setIsInSettings(false);
            localStorage.setItem('token', null);
            window.location.reload();
            // TODO USE LOGOUT API ENDPOINT
        } catch (error) {
            console.error("Sign out failed:", error.message);
        }
    };

    const clickedcloseAuth = () => {
        closeAuth();
    }

    const goToSettings = () => {
        setIsInAboutPanel(false);
        setIsInInvestorsPanel(false);
        setIsInPrivacyPanel(false);
        setIsInTacPanel(false);
        setIsInSettings(true);
        closeAuth();
    }

    return (
        openAuth && (
            <div className={`loginForm ${isInAboutPanel || isInInvestorsPanel || isInPrivacyPanel || isInTacPanel ? 'inabout' : ''}`}>
                <span className="closeAuth" onClick={closeAuth}>
                    <IoClose />
                </span>
                {forgotPasswordScreen ? (
                    <div>
                        {loggedInToAPI ? (
                            <div>
                                {/* <h2 className="authEmail">User: {userEmail}</h2> */}
                                <div className="loggedInButtonBox">
                                    <button
                                        type="button"
                                        className="settingsBtn"
                                        onClick={() => goToSettings()}
                                    >
                                        Settings
                                    </button>
                                    <button
                                        type="button"
                                        className="signOutBtn"
                                        onClick={handleSignOut}
                                    >
                                        Sign Out
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <h2>Account</h2>
                                <form>
                                    <button
                                        className="loginBtn"
                                        type="button"
                                        onClick={handleLogin}
                                    >
                                        Login/Register
                                    </button>
{/* 
                                    <button
                                        className="loginBtn"
                                        type="button"
                                        onClick={handleRegister}
                                    >
                                        Register
                                    </button> */}


                                    <br />
                                    {/* <button
                                        type="button"
                                        className="forgotPasswordBtn"
                                        onClick={handleToggleForgotPasswordScreen}
                                    >
                                        Forgot sign in details?
                                    </button> */}
                                </form>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        {forgotPasswordEmailSent ? (
                            <div>
                                <h2>Forgotten Details</h2>
                                <p>
                                    We’ll send you a code to <br />
                                    access your account
                                </p>
                                <label>
                                    <input
                                        type="email"
                                        value={email}
                                        className="emailInputForgotPassword"
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Email Address"
                                    />
                                </label>
                                <button
                                    type="button"
                                    className="backToLoginBtn"
                                    onClick={handleToggleForgotPasswordScreen}
                                >
                                    Back to Login
                                </button>
                            </div>
                        ) : (
                            <div>
                                <h2>Forgot Password Email Sent</h2>
                                <p className="sentMessage">
                                    We’ve sent you an email with a link to reset your password
                                </p>
                                <button
                                    type="button"
                                    className="backToLoginBtn"
                                    onClick={handleToggleForgotPasswordScreen}
                                >
                                    Back to Login
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    );
};

export default Login;
