import React, { useRef, useCallback, useEffect } from 'react';
import { animated } from '@react-spring/web';
import CompareButtonMobile from './draggableDrawerComponents/compareButtonMobile';
import ForecastButtonMobile from './draggableDrawerComponents/forecastButtonMobile';
import LandTypesButtonMobile from './draggableDrawerComponents/landTypesButtonMobile';
import LandUseButtonMobile from './draggableDrawerComponents/landUseButtonMobile';
import PieChartButtonMobile from './draggableDrawerComponents/pieChartButtonMobile';

const DraggableDrawer = ({
    PieChartselectionCancelOnClick,
    LandtypeSelectionCancelOnClick,
}) => {

    function CustomCarousel({ children }) {
        const slider = useRef(null);
        const isDown = useRef(false);
        const startX = useRef(0);
        const scrollLeft = useRef(0);

        const startDragging = useCallback((e) => {
            isDown.current = true;
            startX.current = e.pageX - slider.current.offsetLeft;
            scrollLeft.current = slider.current.scrollLeft;
            slider.current.classList.add('active');
        }, []);

        const stopDragging = useCallback(() => {
            isDown.current = false;
            slider.current.classList.remove('active');
        }, []);

        const handleMouseMove = useCallback((e) => {
            if (!isDown.current) return;
            e.preventDefault();
            const x = e.pageX - slider.current.offsetLeft;
            const walk = x - startX.current;
            slider.current.scrollLeft = scrollLeft.current - walk;
        }, []);

        useEffect(() => {
            const currentSlider = slider.current;
            currentSlider.addEventListener("mousedown", startDragging);
            currentSlider.addEventListener("mouseleave", stopDragging);
            currentSlider.addEventListener("mouseup", stopDragging);
            currentSlider.addEventListener("mousemove", handleMouseMove);

            return () => {
                currentSlider.removeEventListener("mousedown", startDragging);
                currentSlider.removeEventListener("mouseleave", stopDragging);
                currentSlider.removeEventListener("mouseup", stopDragging);
                currentSlider.removeEventListener("mousemove", handleMouseMove);
            };
        }, [startDragging, stopDragging, handleMouseMove]);

        return (
            <div className="items" ref={slider}>
                {children}
            </div>
        );
    }

    function Box({ index }) {
        return <div className="box">Box {index}</div>;
    }

    return (
        <div style={{ zIndex: 1000, position: 'absolute' }}>
            <animated.div
                style={{
                    touchAction: 'none',
                    background: 'white',
                    boxShadow: '0px -2px 10px rgba(0,0,0,0.2)',
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: '40px',
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    userSelect: 'none',
                }}
            >
                <CustomCarousel>
                    <div class="mobile-grid-item_blocked">
                        <PieChartButtonMobile
                            PieChartselectionCancelOnClick={PieChartselectionCancelOnClick}
                        />
                    </div>
                    <div class="mobile-grid-item_blocked">
                        <LandTypesButtonMobile
                            LandtypeSelectionCancelOnClick={LandtypeSelectionCancelOnClick}
                        />
                    </div>
                    <div class="mobile-grid-item_blocked">
                        <LandUseButtonMobile
                            handleLandUseClick
                        />
                    </div>
                    <div class="mobile-grid-item_blocked">
                        <ForecastButtonMobile
                            ModifySelectionCancelOnClick
                        />
                    </div>
                    <div class="mobile-grid-item_blocked">
                        <CompareButtonMobile
                            handleCompareClick
                        />
                    </div>
                </CustomCarousel>               
            </animated.div>
        </div>
    );
};

export default DraggableDrawer;
