import React from 'react';
import { presetColors } from '../../../../libs/colours';

const RenderedPTagsMobile = ({
  selectedLandTypes,
  handleLandtypePTagClick,
  uniqueTextureNamesArray,
  isUKHabColoursActive
}) => {
  // Sort items alphabetically before rendering
  const items = uniqueTextureNamesArray.sort((a, b) => a.localeCompare(b));
  const reset = 'Reset';
  const hideall = 'Hide all';

  const handleLandtypePTagClickHide = () => {
    handleLandtypePTagClick(reset)
    handleLandtypePTagClick(hideall);
  }

  return (
    <>
      <div className="ResetLandtypeListButton" onClick={() => handleLandtypePTagClick(reset)}>Reset</div>
      <div className="HideAllLandtypeListButton" onClick={() => handleLandtypePTagClickHide()}>Hide all</div>
      <div className="land_types_accum_landkey">
        {items.map((item) => {
          const isSelected = selectedLandTypes.includes(item);
          const backgroundColor = presetColors[item] || 'transparent'; // Fallback to 'transparent' if no color is found
          const truncatedItem = item.length > 30 ? `${item.substring(0, 30)}...` : item; // Truncate item at 22 characters
          const imageSource = `${process.env.PUBLIC_URL}/ukhab/${item}.png`;
          return (
            <div key={item} className={`land_type_list_general_p ${isSelected ? 'selectedLandType' : ''}`} onClick={() => handleLandtypePTagClick(item)}>
              {isUKHabColoursActive ?
                <img className='ukhabcolorcircle' src={imageSource} alt={item} />
                :
                <span className='color_circle' style={{ backgroundColor }}></span>
              }
              <div className="land_type_item_text">
                <p className="land_type_item_key">{truncatedItem}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RenderedPTagsMobile;
