import React, { useEffect, useRef, useState } from "react";
import Chart from 'chart.js/auto';
import { colorPaletteOptions } from '../../../core/globalVariables.js';

const PieChart = ({
  geojson,
  isViewingPieChart,
  selectedMetric,
  piechartRef,
  handleEnlargePieButtonClick,
  selectedYearEditing,
  universalParcelDifferentiator,
  universalColorPaletteName,
  polygonCustomLayerRef
}) => {
  const [selectedLandType, setSelectedLandType] = useState(null);

  const getDifferentiatorColorMap = (geojson) => {
    const uniqueDifferentiators = new Set(
      geojson.features.map(feature =>
        feature.feature_data_simple_timeseries[universalParcelDifferentiator]?.[selectedYearEditing]
      )
    );

    const colorPalette = colorPaletteOptions[universalColorPaletteName];
    const differentiatorColorMap = {};

    let index = 0;
    uniqueDifferentiators.forEach((differentiator) => {
      const color = colorPalette[index % colorPalette.length];
      differentiatorColorMap[differentiator] = color;

      index++;
    });

    return differentiatorColorMap;
  };

  // Calculate percentages of each differentiator
  const calculatePercentages = (geojson) => {
    const sumTotal = {};
    let grandTotal = 0;

    geojson.features.forEach(feature => {
      const propertyValue = feature.feature_data_simple_timeseries[universalParcelDifferentiator]?.[selectedYearEditing];
      const metric = feature.feature_data_simple_timeseries[selectedMetric]?.[selectedYearEditing];

      if (propertyValue !== undefined && metric !== undefined) {
        const roundedMetric = Math.round(metric);
        sumTotal[propertyValue] = (sumTotal[propertyValue] || 0) + roundedMetric;
        grandTotal += roundedMetric;
      }
    });

    const percentages = {};
    for (const property in sumTotal) {
      percentages[property] = (sumTotal[property] / grandTotal * 100).toFixed(2);
    }

    return percentages;
  };

  useEffect(() => {
    const ctx = document.getElementById('summary').getContext('2d');
    if (piechartRef.current) {
      piechartRef.current.destroy();
    }

    if (isViewingPieChart && geojson) {
      const percentages = calculatePercentages(geojson);
      const labels = Object.keys(percentages);
      const dataValues = Object.values(percentages);
      const differentiatorColorMap = getDifferentiatorColorMap(geojson);

      console.log(differentiatorColorMap)

      const backgroundColors = labels.map(label => differentiatorColorMap[label] || '#ccc'); // Default color if not found

      piechartRef.current = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            data: dataValues,
            backgroundColor: backgroundColors,
            hoverOffset: 4
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false,
            },
          },
          animation: false,
        }
      });
    }

    return () => {
      if (piechartRef.current) {
        piechartRef.current.destroy();
      }
    };
  }, [geojson, isViewingPieChart, selectedMetric, selectedYearEditing, piechartRef]);

  const handleLandTypeClick = (key) => {
    const chart = piechartRef.current;

    if (key === 'reset') {
      chart.setActiveElements([]);
      chart.update();
      setSelectedLandType(null);
      polygonCustomLayerRef.current.adjustFeatureOpacitiesPerDifferentiator(key);
      return;
    }

    const activeElementIndex = chart.data.labels.indexOf(key);
    chart.setActiveElements([{ datasetIndex: 0, index: activeElementIndex }]);
    chart.update();
    setSelectedLandType(key);
    polygonCustomLayerRef.current.adjustFeatureOpacitiesPerDifferentiator(key);
  };

  return (
    <>
      {isViewingPieChart && geojson && (
        <>
          <canvas id="summary" className="pieChart" />
          <div className="enlargePieButton" onClick={handleEnlargePieButtonClick}>
            <button>Enlarge</button>
          </div>
          <div onMouseOut={() => handleLandTypeClick('reset')} className="land_types_accum">
            {Object.entries(calculatePercentages(geojson))
              .sort(([, valueA], [, valueB]) => valueB - valueA)
              .map(([key, value]) => {
                const capitalisedKey = key.charAt(0).toUpperCase() + key.slice(1);
                return (
                  <div
                    key={key}
                    className={`land_type_item ${selectedLandType === key ? 'selected' : ''}`}
                    onMouseMove={() => handleLandTypeClick(key)}
                  >
                    <span className="color_circle" style={{ backgroundColor: getDifferentiatorColorMap(geojson)[key] || '#ccc' }}></span>
                    <div className="land_type_item_text">
                      <p className="land_type_item_key">{capitalisedKey.length > 22 ? `${capitalisedKey.substring(0, 22)}...` : capitalisedKey}</p>
                      <p className="land_type_item_value">{value}%</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </>
  );
};

export default PieChart;
