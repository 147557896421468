import React, { useEffect } from 'react';
import { useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import SaveOptions from './topSubMenuComponents/saveOptions.js';
import SearchBar from './topSubMenuComponents/SearchBar.js';
import Geolocate from './topSubMenuComponents/geolocate.js';
import Compass from './topSubMenuComponents/compass.js';
import ConverterPortal from './topSubMenuComponents/converterPortal.js';

const TopSubMenu = ({
    openFilenameInputModal,
    saveCurrentDataSetClick,
    loadMenuClick,
    mapboxTokenRef,
    globalState,
    globalRefs,
    isInAboutPanel,
    isInInvestorsPanel,
    isInPrivacyPanel,
    isInTacPanel,
    resetAboutPanels,
    isInSettings,
    mapbearing,
    loggedInToAPI,
    universalParcelDifferentiator
}) => {

    const [totalSumMetric, setTotalSumMetric] = useState(0);
    let selectedMetric = globalState.selectedMetric;
    let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
    let selectedYearEditing = globalState.selectedYearEditing;

    const backToMap = () => {
        resetAboutPanels();
    }

    let totalSum = 0;

    useEffect(() => {
        function check() {
            if (longstandingGeoJSONRef.current) {
                for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
                    let metricValue;
                    if (longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries[selectedMetric] !== undefined) {
                        metricValue = longstandingGeoJSONRef.current.features[i].feature_data_simple_timeseries[selectedMetric][selectedYearEditing];
                        if (metricValue !== undefined) {
                            totalSum += metricValue;
                        }
                    }
                }
            }
            setTotalSumMetric(totalSum);
        }
        check();
    }, [selectedMetric, longstandingGeoJSONRef.current, selectedYearEditing]);

    return (
        <div className={`top_Submenu ${isInAboutPanel || isInInvestorsPanel || isInPrivacyPanel || isInTacPanel || isInSettings ? 'inabout' : ''}`}>

            <BrowserView>

                {isInAboutPanel || isInInvestorsPanel || isInPrivacyPanel || isInTacPanel || isInSettings ? (
                    <div className='topSubmenuBack'>
                        <button className='topSubmenuBackButton' onClick={backToMap}>Back to Map</button>
                    </div>
                ) :
                    <div>
                        <SaveOptions
                            openFilenameInputModal={openFilenameInputModal}
                            saveCurrentDataSetClick={saveCurrentDataSetClick}
                            loadMenuClick={loadMenuClick}
                            loggedInToAPI={loggedInToAPI}
                        />

                        <SearchBar
                            mapboxTokenRef={mapboxTokenRef}
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Geolocate
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Compass
                            globalRefs={globalRefs}
                            globalState={globalState}
                            mapbearing={mapbearing}
                        />

                        <ConverterPortal
                            globalRefs={globalRefs}
                            globalState={globalState}
                            mapbearing={mapbearing}
                        />

                        <div className='top_Submenu_metricDisplay'>
                            <p className='top_Submenu_metricDisplayMetricTitle'>Metric Total: </p>
                            <p className='top_Submenu_metricDisplayMetric'>
                                {totalSumMetric.toFixed(2)}
                            </p>
                        </div>

                        <div className='top_Submenu_metricTotalsDisplay'>
                            <p className='top_Submenu_metricTotalsDisplayMetricTitle'>Metric Selected: </p>
                            <p className='top_Submenu_metricTotalsDisplayMetric'>
                                {selectedMetric}
                            </p>
                        </div>

                    </div>
                }

            </BrowserView>
            <MobileView>

                {isInAboutPanel || isInInvestorsPanel || isInPrivacyPanel || isInTacPanel || isInSettings ? (
                    <div className='topSubmenuBack'>
                        <button className='topSubmenuBackButton' onClick={resetAboutPanels}>Back to Map</button>
                    </div>
                ) : (
                    <div>
                        <SaveOptions
                            openFilenameInputModal={openFilenameInputModal}
                            saveCurrentDataSetClick={saveCurrentDataSetClick}
                            loadMenuClick={loadMenuClick}
                        />

                        <SearchBar
                            mapboxTokenRef={mapboxTokenRef}
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Geolocate
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Compass
                            globalRefs={globalRefs}
                            globalState={globalState}
                            mapbearing={mapbearing}
                        />

                        <ConverterPortal
                            globalRefs={globalRefs}
                            globalState={globalState}
                            mapbearing={mapbearing}
                        />

                    </div>
                )}

            </MobileView>

        </div >

    );
};

export default TopSubMenu;