import { IoMdLocate } from "react-icons/io";
import { isMobile, isTablet } from 'react-device-detect';
import mapboxgl from 'mapbox-gl';

const Geolocate = ({ globalRefs, globalState }) => {

    let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
    let mapRef = globalRefs.mapRef;

    let mobileStatus = isMobile ? "mobile" : "not-mobile";

    if (isTablet) {
        mobileStatus = "tablet";
    }

    return (
        <div className={`geolocate ${mobileStatus}`}>
            {isMobile ? <>
            <IoMdLocate className="geoIcon" color="#07EA77" size={25} /> 
            </>
            :
                <>

                    <button
                        className='geolocate__button'
                        onClick={() => {
                            if (navigator.geolocation) {
                                navigator.geolocation.getCurrentPosition((position) => {
                                    const { latitude, longitude } = position.coords;
                                    polygonCustomLayerRef.current.cameraFlyToLatLong(latitude, longitude);
                                    polygonCustomLayerRef.current.addUserDot();
                                });
                            } else {
                                alert('Geolocation is not supported by this browser.');
                            }
                        }}
                    >
                        <p>Locate Me</p>
                        <IoMdLocate className="geoIcon" color="#07EA77" size={25} />
                    </button>

                </>
            }

        </div>
    );
}

export default Geolocate;