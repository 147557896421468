import { useRef } from 'react';
import * as THREE from 'three';
import { useState } from 'react';

export let map;

export let fmesh = new THREE.Object3D();

export let fullmesh = new THREE.Object3D();

export function useGlobalRefsConverting() {
  // Refs
  const mapRef = useRef(null);
  const drawRef = useRef(null);
  const centroidRef = useRef(null);
  const polygonCustomLayerRef = useRef(null);
  const longstandingGeoJSONRef = useRef(null);
  const mapContainerRef = useRef(null);
  const mapboxTokenRef = useRef('pk.eyJ1IjoiamV2b25tYWhvbmV5IiwiYSI6ImNrbjRpOThhbzBsOTkycm5xYjVodGlhZnoifQ.nUA78X2hM4qVWn-xD8l3lw');
  
  return {
    mapRef,
    drawRef,
    centroidRef,
    polygonCustomLayerRef,
    longstandingGeoJSONRef,
    mapContainerRef,
    mapboxTokenRef
  };
}

export function useGlobalStateConverting() {

  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapLoadedData, setMapLoadedData] = useState('');
  const [parcelDifferentiator, setParcelDifferentiator] = useState(0);
  const [colorPaletteName, setColorPaletteName] = useState('viridis');

  return {
    mapLoaded,
    setMapLoaded,
    mapLoadedData,
    setMapLoadedData,
    parcelDifferentiator,
    setParcelDifferentiator,
    colorPaletteName,
    setColorPaletteName
  };
}