// DrawComponent.js
import * as turf from '@turf/turf';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { v4 as uuidv4 } from 'uuid';

async function initializeMapboxDraw(globalRefs, globalState) {

  let mapRef = globalRefs.mapRef;
  let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  let drawRef = globalRefs.drawRef;
  let setIsLoading = globalState.setIsLoading;
  let selectedYearEditing = globalState.selectedYearEditing;
  let setIsLanduse = globalState.setIsLanduse;

  let setHasCreatedNewPolygonInModify = globalState.setHasCreatedNewPolygonInModify;

  let updating = 0;
  let creating = 0;
  let deleting = 0;

  let selectedMetric = globalState.selectedMetric;
  let isDrawing = globalState.isDrawing;

  let setFinishedEditing = globalState.setFinishedEditing;

  if (isDrawing) {
    if (drawRef.current) {
      mapRef.current.removeControl(drawRef.current);
      drawRef.current = null;
    }
  }

  // Remove previous listeners if they exist
  if (drawRef.current && drawRef.current.listeners) {
    mapRef.current.off('draw.update', drawRef.current.listeners.updateHandler);
    mapRef.current.off('draw.create', drawRef.current.listeners.createHandler);
    mapRef.current.off('draw.delete', drawRef.current.listeners.deleteHandler);
  }

  // Initialize MapboxDraw only if it's not already initialized
  drawRef.current = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: true,
    },
  });

  mapRef.current.addControl(drawRef.current);

  // Add unique IDs to features in longstandingGeoJSONRef if they don't have an ID
  longstandingGeoJSONRef.current.features = longstandingGeoJSONRef.current.features.map(feature => ({
    ...feature,
    id: feature.id || uuidv4() // Assign a new ID if one doesn't exist
  }));

  // Add GeoJSON with features that now have unique IDs
  if (drawRef.current && longstandingGeoJSONRef.current.features.length > 0) {
    drawRef.current.add(longstandingGeoJSONRef.current);
  } else {
    console.error("No valid features to add to MapboxDraw.");
  }

  // Define new listeners
  const updateListener = drawUpdateHandler(mapRef, longstandingGeoJSONRef, updating, setFinishedEditing);
  const createListener = drawCreateHandler(mapRef, longstandingGeoJSONRef, selectedYearEditing, selectedMetric, creating, setHasCreatedNewPolygonInModify, setIsLanduse, setFinishedEditing);
  const deleteListener = drawDeleteHandler(mapRef, longstandingGeoJSONRef, selectedYearEditing, deleting, setFinishedEditing);

  // Store references to the new listeners in the drawRef for later removal

  drawRef.current.listeners = {
    updateHandler: updateListener,
    createHandler: createListener,
    deleteHandler: deleteListener
  };

  // Attach the new listeners to the map

  mapRef.current.on('draw.update', updateListener);
  mapRef.current.on('draw.create', createListener);
  mapRef.current.on('draw.delete', deleteListener);

  setIsLoading(false);
}

// Draw events
export const drawUpdateHandler = (mapRef, longstandingGeoJSONRef, updating, setFinishedEditing) => (e) => {
  const updatedFeatures = e.features;
  if (updatedFeatures.length > 0) {
    updatedFeatures.forEach((updatedFeature) => {
      longstandingGeoJSONRef.current.features.forEach((existingFeature, i) => {
        if (existingFeature.id === updatedFeature.id) {
          longstandingGeoJSONRef.current.features[i].geometry = updatedFeature.geometry; // Update geometry only
        }
      });
    });
  }
};

// Draw create event

export const drawCreateHandler = (mapRef, longstandingGeoJSONRef, selectedYearEditing, selectedMetric, creating, setHasCreatedNewPolygonInModify, setIsLanduse, setFinishedEditing) => (e) => {
  if (creating === 0) {
    const originalFeature = longstandingGeoJSONRef.current.features[0]; // Assuming the first feature is the template
    const newFeature = {
      ...originalFeature, // Copy properties from the first feature
      id: uuidv4(), // New unique ID
      geometry: e.features[0].geometry // Use the newly drawn geometry
    };
    longstandingGeoJSONRef.current.features.push(newFeature); // Add the new feature
    creating = 1;
  }
}

// Draw delete event
export const drawDeleteHandler = (mapRef, longstandingGeoJSONRef, selectedYearEditing, deleting, setFinishedEditing) => (e) => {
  if (deleting === 0) {
    const deletedFeatures = e.features;
    if (deletedFeatures.length > 0) {
      deletedFeatures.forEach((deletedFeature) => {
        longstandingGeoJSONRef.current.features = longstandingGeoJSONRef.current.features.filter(
          (feature) => feature.id !== deletedFeature.id // Match and remove feature by ID
        );
      });
    }
    
    // Remove 'id' property from all features after deletion is done
    longstandingGeoJSONRef.current.features = longstandingGeoJSONRef.current.features.map(feature => {
      const { id, ...rest } = feature; // Exclude the 'id' property
      return rest;
    });

    deleting = 1;
  }
};


export default initializeMapboxDraw;
