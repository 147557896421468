import React, { useEffect, useRef } from 'react';
import SkylarkPolygonLayer from '../core/skylarkPolygonLayer.js';
import { bbox } from '@turf/turf';  // Bounding box function from Turf
import { center } from '@turf/turf';  // Center function from Turf

import {
    fmesh,
    fullmesh,
    map,
} from '../core/globalVariablesConverting.js';

const DisplayDataMetrics = ({
    globalRefsConverting,
    globalStateConverting,
}) => {

    const mapRef = globalRefsConverting.mapRef;
    const polygonCustomLayerRef = globalRefsConverting.polygonCustomLayerRef;
    const longstandingGeoJSONRef = globalRefsConverting.longstandingGeoJSONRef;
    let centroidRef = globalRefsConverting.centroidRef;
    let mapLoaded = globalStateConverting.mapLoaded;
    let mapLoadedData = globalStateConverting.mapLoadedData;
    let mapContainerRef = globalRefsConverting.mapContainerRef;

    let parcelDifferentiator = globalStateConverting.parcelDifferentiator;
    let colorPaletteName = globalStateConverting.colorPaletteName;

    const loadData = async () => {
        try {
            // Remove the existing layers - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            if (mapRef.current.getLayer('custom-layer-id')) {
                fmesh.children.forEach((child) => {
                    child.geometry.dispose();
                    child.material.dispose();
                });
                fullmesh.children.forEach((child) => {
                    fullmesh.remove(child);
                });
                fmesh.children.forEach((child) => {
                    fmesh.remove(child);
                });
                fmesh.children.length = 0;
                fullmesh.children.length = 0;
                mapRef.current.removeLayer('custom-layer-id');
                addLayertoMap();
            } else {
                addLayertoMap();
            }

            function addLayertoMap() {
                const geoJsonData = mapLoadedData;
                centroidRef.current = center(geoJsonData);
                longstandingGeoJSONRef.current = geoJsonData;
                // Initialize the custom layer - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                polygonCustomLayerRef.current = new SkylarkPolygonLayer(
                    'Polygons',
                    centroidRef.current.geometry.coordinates[1],
                    centroidRef.current.geometry.coordinates[0],
                    geoJsonData,
                    globalRefsConverting,
                    globalStateConverting,
                    false,
                    parcelDifferentiator,
                    colorPaletteName
                );
                // Add the custom layer to the map - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                mapRef.current.addLayer({
                    id: 'custom-layer-id',
                    type: 'custom',
                    renderingMode: '3d',
                    onAdd: function (map, gl) {
                        this.skylarkLayer = polygonCustomLayerRef.current;
                        this.skylarkLayer.onAdd(map, gl);
                    },
                    render: function (gl, matrix) {
                        this.skylarkLayer.render(gl, matrix);
                    }
                });
                // Process features - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                let newDataArray = [];
                let invalidFeatures = [];
                for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
                    let feature = { ...longstandingGeoJSONRef.current.features[i] };
                    delete feature.type;
                    let coordinates;
                    if (feature.geometry.type === 'Polygon') {
                        coordinates = feature.geometry.coordinates[0];
                    } else if (feature.geometry.type === 'MultiPolygon') {
                        coordinates = feature.geometry.coordinates[0][0];
                    }
                    if (coordinates.length < 4) {
                        invalidFeatures.push(feature);
                    } else {
                        newDataArray.push(feature);
                    }
                }
                longstandingGeoJSONRef.current.features = newDataArray;
                // Calculate bounding box and fit map - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                const bounds = bbox(geoJsonData);
                mapRef.current.fitBounds(bounds, {
                    padding: 20,
                    speed: 1,
                    pitch: 0
                });
            }
            // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        } catch (error) {
            alert('Please try a different CRS', error);
            console.error('Error loading data:', error);
        }
    };

    useEffect(() => {
        if (mapLoadedData && mapLoaded) {
            loadData();
        }
    }, [mapLoadedData, mapLoaded]);

    return (
        <div className='map'>
            <div ref={mapContainerRef} id='mapContainerRef' className='map-container'></div>
        </div>
    )
};

export default DisplayDataMetrics;
