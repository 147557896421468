
import mapboxgl from 'mapbox-gl';

const InitializeMap = (globalRefs, globalState) => {
    let mapRef = globalRefs.mapRef;
    let mapContainerRef = globalRefs.mapContainerRef;
    let mapboxTokenRef = globalRefs.mapboxTokenRef;
    let setMapLoaded = globalState.setMapLoaded;

    if (!mapRef.current) {
        mapboxgl.accessToken = mapboxTokenRef.current;
        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/jevonmahoney/cm043wv5y00eo01qsds3td38w',
            center: [-0.9061603891426653, 54.121878725877096],
            zoom: 5,
            pitch: 60,
            bearing: 0,
            preserveDrawingBuffer: true
        });
        mapRef.current.on('load', () => {
            if (mapRef.current.isStyleLoaded()) {
                setMapLoaded(true);
            }
        });
    }
};


export default InitializeMap;
