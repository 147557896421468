import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import RenderedPTags from "./leftSubMenuComponents/RenderedPTags.js";
import PieChart from "./leftSubMenuComponents/pieChart.js";
import ModifyPanel from "./leftSubMenuComponents/ModifyPanel.js";
import RenderedPTagsWithKey from "./leftSubMenuComponents/RenderedPTagsWithKey.js";
import DropdownList from './leftSubMenuComponents/dropdownList.js';

const LeftSubMenu = ({
  endDrawOnClick,
  endDrawCancelOnClick,
  isSelectingLandtypes,
  toggleLeftSubDrawer,
  isLeftSubDrawerOpen,
  handleLandtypePTagClick,
  selectedLandTypes,
  isViewingPieChart,
  longstandingGeoJSONRef,
  selectedYearEditing,
  isModifying,
  isComparing,
  selectedMetric,
  uniqueTextureNamesArray,
  isLanduse,
  polygonCustomLayerRef,
  isVisible,
  dropdownhighlightedIndex,
  setdropdownhighlightedIndex,
  setIsLanduse,
  piechartRef,
  handleEnlargePieButtonClick,
  setcomparingLandtypeSelectedOption,
  resetLeftSubMenu,
  setisLeftSubDrawerOpen,
  storedfeaturecurrentlandtype,
  setStoredFeatureCurrentLandtype,
  temporaryGeoJSONIntendedForSaving,
  setTemporaryGeoJSONIntendedForSaving,
  setFinishedEditing,
  finishedEditing,
  universalParcelDifferentiator,
  universalColorPaletteName
}) => {

  return (
    <div>
      <Drawer
        open={isLeftSubDrawerOpen}
        direction='left'
        className='leftSubMenu'
        enableOverlay={false}
        duration={150}
      >

        <div onClick={toggleLeftSubDrawer} className="leftSubMenu_cancel">
          <button>
            Cancel
          </button>
        </div>

        <div>
          {isSelectingLandtypes ? (
            <div className="genericSubMenuHelpBox">
              <div className="genericSubMenuHelpBoxinner">
                <h1>Land Key</h1>
                <div className='land_type_list_general'>
                  <RenderedPTags
                    selectedLandTypes={selectedLandTypes}
                    handleLandtypePTagClick={handleLandtypePTagClick}
                    uniqueTextureNamesArray={uniqueTextureNamesArray}
                    universalParcelDifferentiator={universalParcelDifferentiator}
                    selectedYearEditing={selectedYearEditing}
                    longstandingGeoJSONRef={longstandingGeoJSONRef}
                    universalColorPaletteName={universalColorPaletteName}
                  />
                </div>
              </div>
            </div>
          ) : (
            null
          )}
        </div>

        <div>
          {isViewingPieChart ? (
            <div className="genericSubMenuHelpBox">
              <div className="genericSubMenuHelpBoxinner">
                <h1>Pie Chart</h1>
                <div className="piechartdiv">
                  <PieChart
                    geojson={longstandingGeoJSONRef.current}
                    isViewingPieChart={isViewingPieChart}
                    selectedMetric={selectedMetric}
                    handleLandtypePTagClick={handleLandtypePTagClick}
                    polygonCustomLayerRef={polygonCustomLayerRef}
                    piechartRef={piechartRef}
                    handleEnlargePieButtonClick={handleEnlargePieButtonClick}
                    selectedYearEditing={selectedYearEditing}
                    universalParcelDifferentiator={universalParcelDifferentiator}
                    universalColorPaletteName={universalColorPaletteName}
                  />
                </div>
              </div>
            </div>
          ) : (
            null
          )}
        </div>

        <div>
          <div>
            {isModifying ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Modify Map</h1>
                  {isModifying &&
                    <ModifyPanel
                      endDrawOnClick={endDrawOnClick}
                      endDrawCancelOnClick={endDrawCancelOnClick}
                      selectedYearEditing={selectedYearEditing}
                      setFinishedEditing={setFinishedEditing}
                      finishedEditing={finishedEditing}
                    />}
                  <p>
                    <h3>Modifying a Map</h3>
                    1 Click the polygon to Modify.<br />
                    2 Double-click the yellow points.<br />
                    3 Drag the points to your desired new shape.<br />
                    4 Double-click the polygon to finalise.<br />
                    <h3>Creating a New Land Type</h3>
                    1 Click the "Draw" button located above.<br />
                    2 Click the map to create a new boundary.<br />
                    3 Continue clicking to draw the desired shape.<br />
                    4 Double-click to confirm the new boundary.<br />
                    5 Click the "Confirm" button to finalise.<br />
                    6 Select the new land type for the area.
                  </p>
                </div>
              </div>
            ) : (
              null
            )}
            {isComparing ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Land Type Key</h1>
                  <p>
                    <RenderedPTagsWithKey
                      selectedLandTypes={selectedLandTypes}
                      handleLandtypePTagClick={handleLandtypePTagClick}
                      uniqueTextureNamesArray={uniqueTextureNamesArray}
                      setcomparingLandtypeSelectedOption={setcomparingLandtypeSelectedOption}
                    />
                  </p>
                </div>
              </div>
            ) : (
              null
            )}
            {isLanduse ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Change Land Use</h1>
                  <p>
                    1 Select the polygon on the map that you want to change the land use for<br />
                    2 From the list of available land types, choose the new land type you want to assign to the selected area.<br />
                    3 Click the "Confirm" button to apply the new land type to the selected polygon.
                  </p>
                  <DropdownList
                    isVisible={isVisible}
                    polygonCustomLayerRef={polygonCustomLayerRef}
                    selectedYearEditing={selectedYearEditing}
                    dropdownhighlightedIndex={dropdownhighlightedIndex}
                    setdropdownhighlightedIndex={setdropdownhighlightedIndex}
                    uniqueTextureNamesArray={uniqueTextureNamesArray}
                    isLandUse={isLanduse}
                    setIsLanduse={setIsLanduse}
                    resetLeftSubMenu={resetLeftSubMenu}
                    setisLeftSubDrawerOpen={setisLeftSubDrawerOpen}
                    storedfeaturecurrentlandtype={storedfeaturecurrentlandtype}
                    setStoredFeatureCurrentLandtype={setStoredFeatureCurrentLandtype}
                    temporaryGeoJSONIntendedForSaving={temporaryGeoJSONIntendedForSaving}
                    setTemporaryGeoJSONIntendedForSaving={setTemporaryGeoJSONIntendedForSaving}
                    longstandingGeoJSONRef={longstandingGeoJSONRef}
                    universalParcelDifferentiator={universalParcelDifferentiator}
                  />
                </div>
              </div>
            ) : (
              null
            )}
          </div>
        </div>

        <button className='togglerDrawOutside' onClick={toggleLeftSubDrawer}>
          <IoIosArrowBack />
        </button>
      </Drawer>
    </div>
  );
};

export default LeftSubMenu;