import { IoMdLocate } from "react-icons/io";
import { FaCompass } from "react-icons/fa";
import { isMobile } from 'react-device-detect';
import { PiUploadBold } from "react-icons/pi";

const ConverterPortal = ({
    globalRefs,
    globalState,
    mapbearing
}) => {

    let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;

    let setConvertingData = globalState.setConvertingData;

    const mobileStatus = isMobile ? "mobile" : "not-mobile";

    const userEmail = globalState.userEmail;

    if (userEmail === "") {
        return (
            <div className={`converterPortalButton ${mobileStatus}`}>
                <button
                    onClick={() => {
                    }}
                    style={{opacity: .5}} 
                >
                    <p>Upload</p>
                    <PiUploadBold color="#07EA77" style={{opacity: .5}} className="compassIcon" size={25} />
                </button>
            </div>
        );
    } else {

        return (
            <div className={`converterPortalButton ${mobileStatus}`}>
                <button
                    onClick={() => {
                        setConvertingData(true);
                    }}
                >
                    <p>Upload</p>
                    <PiUploadBold color="#07EA77" className="compassIcon" size={25} />
                </button>
            </div>
        );
    }
}

export default ConverterPortal;
