import React, { useState, useRef, useEffect } from 'react';
import DisplayDataMetrics from './components/ui/displayDataMetrics.js';
import InitializeMap from './components/core/mapboxInit.js';
import { useGlobalRefsConverting, useGlobalStateConverting } from './components/core/globalVariablesConverting.js';
import * as turf from '@turf/turf';
import SkylarkPolygonLayer from '../../components/core/skylarkPolygonLayer.js';
import { center } from '@turf/turf';
import { bbox } from '@turf/turf';  // Bounding box function from Turf

import { fmesh, fullmesh } from '../../components/core/globalVariables.js';

const MetricSelection = ({
    geojson,
    globalRefs,
    globalState,
}) => {

    const newGeojsonRef = useRef(null);

    const globalRefsConverting = useGlobalRefsConverting();
    const globalStateConverting = useGlobalStateConverting();

    const mapRef = globalRefs.mapRef;
    const polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
    const setConvertingData = globalState.setConvertingData;
    const setIsViewingConvertedData = globalState.setIsViewingConvertedData;
    let setUniversalParcelDifferentiator = globalState.setUniversalParcelDifferentiator;
    let setUniversalColorPaletteName = globalState.setUniversalColorPaletteName;
    let centroidRef = globalRefs.centroidRef;
    let originalGeoJSONforComparingRef = globalRefs.originalGeoJSONforComparingRef;

    const [uniqueKeys, setUniqueKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [metricsInitiallySelected, setMetricsInitiallySelected] = useState('false');
    const [allProperties, setAllProperties] = useState([]);
    const [selectedPalette, setSelectedPalette] = useState(null);

    let longstandingGeoJSONRef = globalRefsConverting.longstandingGeoJSONRef;
    let setMapLoadedData = globalStateConverting.setMapLoadedData;
    let mapLoadedData = globalStateConverting.mapLoadedData;
    let parcelDifferentiator = globalStateConverting.parcelDifferentiator;
    let setParcelDifferentiator = globalStateConverting.setParcelDifferentiator;
    let colorPaletteName = globalStateConverting.colorPaletteName;
    let setColorPaletteName = globalStateConverting.setColorPaletteName;

    const initPaletteSet = useRef(false);

    const palettes = {
        land: ['#006400', '#228B22', '#32CD32', '#90EE90', '#98FB98', '#00FF00'],
        coastal: ['#FFA500', '#FF8C00', '#FF7F50', '#FF6347', '#FF4500', '#FFD700'],
        mixed: ['#FF1493', '#00BFFF', '#7FFF00', '#FF6347', '#DAA520', '#ADFF2F'],
        autumn: ['#FF0000', '#008000', '#FF6347', '#006400', '#FF4500', '#228B22'],
        chemical: ['#FFC0CB', '#FF69B4', '#FF1493', '#FFB6C1', '#FF77FF', '#FF00FF'],
        water: ['#0000FF', '#4682B4', '#87CEEB', '#5F9EA0', '#00BFFF', '#1E90FF']
    };

    useEffect(() => {
        if (mapLoadedData) {
            console.log('Map loaded data:', mapLoadedData);
            InitializeMap(globalRefsConverting, globalStateConverting);
        }
    }, [mapLoadedData]);


    // ALLOCATING METRICS FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    useEffect(() => {
        if (geojson && geojson.features) {
            for (let i = 0; i < geojson.features.length; i++) {
                let feature = geojson.features[i];
                for (let key in feature) {
                    if (feature.hasOwnProperty(key)) {
                        setUniqueKeys((prevKeys) => {
                            if (!prevKeys.includes(key)) {
                                return [...prevKeys, key];
                            }
                            return prevKeys;
                        });
                    }
                }
            }
        }
    }, [geojson]);

    // CHECKBOX FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const handleMetricCheckboxChange = (key) => {
        setSelectedKeys((prevSelected) => {
            if (prevSelected.includes(key)) {
                return prevSelected.filter((selectedKey) => selectedKey !== key);
            } else {
                return [...prevSelected, key];
            }
        });
    };

    // CREATE NEW GEOJSON FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    useEffect(() => {
        if (geojson)
            createNewGeojson();
    }, [geojson]);

    const createNewGeojson = () => {
        const newGeojson = {
            type: 'FeatureCollection',
            features: geojson.features.map((feature) => {
                let newFeature = {
                    type: 'Feature',
                    geometry: feature.geometry,
                    properties: {} // Initialize properties as an empty object
                };
                // Function to merge all keys from nested objects into newFeature.properties
                const mergeNestedProperties = (source) => {
                    if (source && typeof source === 'object') {
                        Object.keys(source).forEach((key) => {
                            if (typeof source[key] === 'object' && source[key] !== null) {
                                Object.keys(source[key]).forEach((nestedKey) => {
                                    newFeature.properties[nestedKey] = source[key][nestedKey];
                                });
                            }
                        });
                    }
                };
                // Merge keys dynamically from nested properties and attributes
                mergeNestedProperties(feature); // This will only include keys from nested objects
                return newFeature;
            })
        };
        for (let i = 0; i < newGeojson.features.length; i++) {
            let feature = newGeojson.features[i];
            let length = turf.length(feature, { units: 'kilometers' });
            let area = turf.area(feature);
            feature.feature_data_standard = {
                created: "2024-01-01T00:00:00+00:00",
                deleted: null
            };
            feature.feature_data_standard_calculated = {
                Shape_Length: length,
                Shape_Area: area,
            };
            feature.skylark_id = generateUUID();
            feature.external_ids = {
                searchlight_property_id: Math.floor(Math.random() * 10000).toString(),
                OBJECTID: Math.floor(Math.random() * 10000).toString(),
                OID_: Math.floor(Math.random() * 10000).toString(),
                id: Math.floor(Math.random() * 10000).toString(),
            };
        }
        convertToTimeSeries(newGeojson);
        longstandingGeoJSONRef.current = newGeojson;
        newGeojsonRef.current = newGeojson; // Update the ref
        const properties = getAllPropertiesForInnerKey(newGeojson);
        setAllProperties(properties);
        setMetricsInitiallySelected('true');
        setMapLoadedData(null);
        setColorPaletteName('land');
        setTimeout(() => {
            setMapLoadedData(longstandingGeoJSONRef.current);
        }, 0);
    };

    // Helper function to generate a UUID
    const generateUUID = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    // CONVERT TO TIME SERIES FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const convertToTimeSeries = (newGeojson) => {
        newGeojson.features.forEach((feature) => {
            feature.feature_data_simple_timeseries = {};
            Object.keys(feature.properties).forEach((key) => {
                feature.feature_data_simple_timeseries[key] = {
                    '2024-01-01T00:00:00+00:00': feature.properties[key] || null,
                    '2027-01-01T00:00:00+00:00': feature.properties[key] || null,
                    '2029-01-01T00:00:00+00:00': feature.properties[key] || null,
                    '2031-01-01T00:00:00+00:00': feature.properties[key] || null
                };
                feature.feature_data_simple_timeseries.BoundaryChange = {
                    '2024-01-01T00:00:00+00:00': "000,000",
                    '2027-01-01T00:00:00+00:00': "000,000",
                    '2029-01-01T00:00:00+00:00': "000,000",
                    '2031-01-01T00:00:00+00:00': "000,000"
                };
            });
        });
    }

    // ALLOCATING PROPERTIES FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const getAllPropertiesForInnerKey = (geojson) => {
        let allProperties = [];
        geojson.features.forEach((feature) => {
            let properties = feature.feature_data_simple_timeseries;
            if (properties) {
                Object.keys(properties).forEach((key) => {
                    if (!allProperties.includes(key)) {
                        allProperties.push(key);
                    }
                });
            }
        });
        return allProperties;
    };

    // CHECKBOX FUNCTIONS FOR PROPERTIES - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const handlePropertyCheckboxChange = (key) => {
        setSelectedKeys([key]);
        setParcelDifferentiator(prevKey => {
            if (prevKey !== key) {
                return key;
            } else {
                return "";
            }
        });
        setMapLoadedData(null);
        setTimeout(() => {
            setMapLoadedData(longstandingGeoJSONRef.current);
        }, 0);
    };

    // PALETTE CLICK FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const handlePaletteClick = (paletteName) => {
        setSelectedPalette(paletteName);
        setColorPaletteName(paletteName);
        setMapLoadedData(null);
        setTimeout(() => {
            setMapLoadedData(longstandingGeoJSONRef.current);

            // AUTO DOWNLOAD FUNCTION - - - - -
            // AUTO DOWNLOAD FUNCTION - - - - -
            // AUTO DOWNLOAD FUNCTION - - - - -
            // AUTO DOWNLOAD FUNCTION - - - - -

            // let downloadable = JSON.stringify(longstandingGeoJSONRef.current, null, 2);
            // let blob = new Blob([downloadable], { type: 'application/json' });
            // let url = URL.createObjectURL(blob);
            // let a = document.createElement('a');
            // a.href = url;
            // a.download = 'map_data.geojson';
            // document.body.appendChild(a);
            // a.click();
            // document.body.removeChild(a); 

            // AUTO DOWNLOAD FUNCTION - - - - -
            // AUTO DOWNLOAD FUNCTION - - - - -
            // AUTO DOWNLOAD FUNCTION - - - - -
            // AUTO DOWNLOAD FUNCTION - - - - -

        }, 0);
    };

    // SET INITIAL PALETTE - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    useEffect(() => {
        if (!initPaletteSet.current) {
            if (!selectedPalette) {
                setSelectedPalette('land');
                setColorPaletteName('land');
            }
            initPaletteSet.current = true;
        }
    }, [selectedPalette]);

    // CREATE HARRIER MAP FUNCTION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const createHarrierMap = () => {
        const newGeojsontoView = newGeojsonRef.current;
        const customCentroid = center(newGeojsontoView);
        centroidRef.current = customCentroid;
        try {
            fmesh.children.forEach((child) => {
                child.geometry.dispose();
                child.material.dispose();
            });
            fullmesh.children.forEach((child) => {
                fullmesh.remove(child);
            });
            fmesh.children.forEach((child) => {
                fmesh.remove(child);
            });
            fmesh.children.length = 0;
            fullmesh.children.length = 0;
            if (mapRef.current && mapRef.current.getLayer('Polygons')) {
                mapRef.current.removeLayer('Polygons');
            }
            polygonCustomLayerRef.current = new SkylarkPolygonLayer(
                'Polygons',
                customCentroid.geometry.coordinates[1],
                customCentroid.geometry.coordinates[0],
                newGeojsontoView,
                globalRefs,
                globalState,
                colorPaletteName,
                parcelDifferentiator
            );
            originalGeoJSONforComparingRef.current = newGeojsontoView;
            mapRef.current.addLayer(polygonCustomLayerRef.current);
            mapRef.current.on('mousedown', (e) => {
                if (e.originalEvent.button === 0) {
                    polygonCustomLayerRef.current.raycast(e.point, false);
                }
            });
            mapRef.current.on('zoom', () => {
                console.log('zoom');
                // console.log(mapRef.current.getZoom());
                let zoomLevel = mapRef.current.getZoom();
                // polygonCustomLayerRef.current.zoomBased_Animate_Larger(zoomLevel);
            });
            const bounds = bbox(longstandingGeoJSONRef.current);
            mapRef.current.fitBounds([[bounds[0], bounds[1]], [bounds[2], bounds[3]]], {
                padding: 20,
                speed: 1
            });
            setConvertingData(false);
            setIsViewingConvertedData(true);
            setUniversalParcelDifferentiator(parcelDifferentiator);
            setUniversalColorPaletteName(colorPaletteName);
        } catch (error) {
            console.error('Error creating Harrier map:', error);
        }
    };

    return (
        <div>

            <div className='fileInterfacecontainer'>
                <div className='leftColumn'>

                    <div className='coreInstructionBlockContainer'>
                        <div className="coreInstructionBlock">1. Upload</div>
                        <div className="coreInstructionBlock">2. Convert</div>
                        <div className="coreInstructionBlockActive">3. Define</div>
                    </div>

                    <h1>Define</h1>

                    {/* <div className="instructions">
                        <p>Select which data to include in your Map</p>
                    </div> */}

                    {/* <div className='metricSelectionOptions'>
                        {uniqueKeys.map((key, index) => {
                            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                            return (
                                <div
                                    className='metricSelectionOption'
                                    key={index}
                                    style={{ display: key === 'geometry' ? 'none' : 'inline-block' }}
                                >
                                    <input
                                        className="custom-checkbox"
                                        type="checkbox"
                                        id={`checkbox-${key}`}
                                        checked={selectedKeys.includes(key)}
                                        onChange={() => handleMetricCheckboxChange(key)}
                                    />
                                    <label htmlFor={`checkbox-${key}`}>{capitalizedKey}</label>
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <div className='SubmitGeoJsonButton' onClick={() => createNewGeojson()}>
                            <button className='converterButton'>Add</button>
                        </div>
                    </div> */}

                    {metricsInitiallySelected === 'true' ? (
                        <div>
                            <div className="instructions">
                                <p>Select the attribute that defines your land data from the dropdown.</p>
                            </div>

                            <div className='metricSelectionOptions'>
                                <select
                                    value={selectedKeys.length > 0 ? selectedKeys[0] : ''}
                                    onChange={(e) => handlePropertyCheckboxChange(e.target.value)}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                        borderRadius: '4px',
                                        color: '#000',
                                        backgroundColor: '#fff',
                                        width: '200px'
                                    }}
                                >
                                    <option value="" disabled>Select</option>
                                    {allProperties.map((key, index) => {
                                        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                                        return key !== 'geometry' ? (
                                            <option key={index} value={key}>
                                                {capitalizedKey}
                                            </option>
                                        ) : null;
                                    })}
                                </select>
                            </div>

                            <div className="instructions">
                                <p>Select a colour palette for your Map</p>
                            </div>

                            <div className='paletteSelectionOptions'>
                                <select
                                    value={selectedPalette || ''}
                                    onChange={(e) => handlePaletteClick(e.target.value)}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                        borderRadius: '4px',
                                        color: '#000',
                                        backgroundColor: '#fff',
                                        width: '200px'
                                    }}
                                >
                                    <option value="" disabled>Select a palette</option>
                                    {Object.keys(palettes).map((paletteName) => (
                                        <option key={paletteName} value={paletteName}>
                                            {paletteName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="createHarrierMapButton" onClick={() => createHarrierMap()}>
                                <button className='createMapButton'>Create Harrier Map</button>
                            </div>
                        </div>
                    ) : null}

                </div>

                <div className='rightColumn'>
                    <DisplayDataMetrics globalRefsConverting={globalRefsConverting} globalStateConverting={globalStateConverting} />
                </div>
            </div>

        </div>
    );
}

export default MetricSelection;
