import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import MobilePieChart from './draggableSubDrawerComponents/mobilePieChart';
import RenderedPTagsMobile from "./draggableSubDrawerComponents/renderedPTagsMobile.js";

const DraggableSubDrawer = ({
    isDraggableSubDrawerOpen,
    setisDraggableSubDrawerOpen,
    isViewingPieChart,
    longstandingGeoJSONRef,
    selectedMetric,
    handleLandtypePTagClick,
    polygonCustomLayerRef,
    piechartRef,
    handleEnlargePieButtonClick,
    isSelectingLandtypes,
    selectedLandTypes,
    uniqueTextureNamesArray,
    selectedYearEditing
}) => {

    const [lastY, setLastY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    let maxHeight = '100%' - 160;

    const [{ y }, set] = useSpring(() => ({
        y: maxHeight,
        config: { tension: 200, friction: 20 }
    }));

    const openDrawer = () => {
        set({ y: 0 });
        setisDraggableSubDrawerOpen(true);
    };

    useEffect(() => {
        if (isDraggableSubDrawerOpen) {
            openDrawer();
        }
    }, [isDraggableSubDrawerOpen]);

    const closeDrawer = (velocity = 0) => {
        setisDraggableSubDrawerOpen(false);
        set({ y: maxHeight + 50, immediate: false, from: { y: y.get() }, velocity: velocity });
    };

    const handleMouseDown = (e) => {
        setLastY(e.clientY || e.touches[0].clientY);
        setIsDragging(true);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const currentY = e.clientY || e.touches[0].clientY;
        const diffY = currentY - lastY;
        setLastY(currentY);
        set({ y: Math.max(0, y.get() + diffY) });
    };

    const handleMouseUp = (e) => {
        setIsDragging(false);
        const currentY = e.clientY || e.changedTouches[0].clientY;
        const shouldClose = currentY > 400;
        shouldClose ? closeDrawer() : openDrawer();
    };

    return (
        <div style={{ zIndex: 1000, position: 'absolute' }}>
            <animated.div
                style={{
                    transform: y.to(y => `translateY(${y}px)`),
                    touchAction: 'none',
                    background: 'white',
                    boxShadow: '0px -2px 10px rgba(0,0,0,0.2)',
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: maxHeight + 'px',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                    padding: '16px',
                    userSelect: 'none',
                    cursor: isDragging ? 'grabbing' : 'grab'
                }}
            >
                <div className='handleouter' style={{ height: '30px', backgroundColor: '#fff', top: '-15px', position: 'relative' }}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={() => isDragging && handleMouseUp()}
                    onTouchStart={handleMouseDown}
                    onTouchMove={handleMouseMove}
                    onTouchEnd={handleMouseUp}>
                    <div className='handle' style={{ width: '25%', top: '10px', height: '5px', background: 'lightgray', borderRadius: '10px', position: 'absolute', left: '37.5%' }}
                    />
                </div>

                <div>
                    {isViewingPieChart ? (
                        <div className='mobileDrawerInner'>
                            <h1>Pie Chart</h1>
                            <div className="mobilepiechartdiv">
                                <MobilePieChart
                                    geojson={longstandingGeoJSONRef.current}
                                    isViewingPieChart={isViewingPieChart}
                                    selectedMetric={selectedMetric}
                                    handleLandtypePTagClick={handleLandtypePTagClick}
                                    polygonCustomLayerRef={polygonCustomLayerRef}
                                    piechartRef={piechartRef}
                                    handleEnlargePieButtonClick={handleEnlargePieButtonClick}
                                    selectedYearEditing={selectedYearEditing}
                                />
                            </div>
                        </div>
                    ) : (
                        null
                    )}
                </div>

                <div>
                    {isSelectingLandtypes ? (
                        <div className="mobileDrawerInner">
                            <h1>Land Key</h1>
                            <div className='land_type_list_general'>
                                <RenderedPTagsMobile
                                    selectedLandTypes={selectedLandTypes}
                                    handleLandtypePTagClick={handleLandtypePTagClick}
                                    uniqueTextureNamesArray={uniqueTextureNamesArray}
                                />
                            </div>
                        </div>
                    ) : (
                        null
                    )}
                </div>

            </animated.div>
        </div>
    );
};

export default DraggableSubDrawer;
