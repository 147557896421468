import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import proj4 from 'proj4';
import { IoClose } from "react-icons/io5";
import { parseShp, parseDbf } from 'shpjs';
import Papa from 'papaparse'; // Import Papa Parse for CSV parsing
import * as XLSX from 'xlsx'; // Import XLSX for handling Excel files

import FileToGeoJSONConversion from './converterComponents/JSON_File/FileToGeoJSONConversion.js';

// Define projections
proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs");
proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
proj4.defs("EPSG:3857", "+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +a=6378137 +b=6378137 +units=m +no_defs");
proj4.defs("EPSG:32630", "+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32631", "+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32632", "+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32633", "+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32634", "+proj=utm +zone=34 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32635", "+proj=utm +zone=35 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32636", "+proj=utm +zone=36 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32637", "+proj=utm +zone=37 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32638", "+proj=utm +zone=38 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:32639", "+proj=utm +zone=39 +datum=WGS84 +units=m +no_defs");

const FileInterface = ({
    globalRefs,
    globalState
}) => {
    const [fileType, setFileType] = useState(null); // to store the uploaded file type
    const [data, setData] = useState(null); // to store the uploaded data
    const [filename, setFilename] = useState(null); // to store the uploaded filename
    const [missingFiles, setMissingFiles] = useState(false); // to track missing .dbf and .prj files
    const [convertScreen, setConvertScreen] = useState(false); // to track the conversion screen

    let setConvertingData = globalState.setConvertingData;

    const closeFileInterface = () => {
        setConvertingData(false);
    }

    const onDrop = useCallback((acceptedFiles) => {
        let shpFile = null;
        let dbfFile = null;
        let prjFile = null;
        let oversizedFiles = []; // Array to hold oversized files
    
        // Identify the uploaded files based on their extensions
        acceptedFiles.forEach(file => {
            const fileExt = file.name.split('.').pop().toLowerCase();
            const isOversized = file.size > 1002 * 1024 * 1024; // Check if file size exceeds 12 MB
            if (isOversized) {
                oversizedFiles.push(file.name); // Add to oversizedFiles array
            } else {
                if (fileExt === 'shp') shpFile = file;
                if (fileExt === 'dbf') dbfFile = file;
                if (fileExt === 'prj') prjFile = file;
            }
        });
    
        // If there are oversized files, set an error message and return
        if (oversizedFiles.length > 0) {
            alert(`We're sorry, the following file(s) exceed the 12 MB limit: ${oversizedFiles.join(', ')}`);
            return; // Stop further processing
        }
    
        if (shpFile) {
            setFileType('shp');
            // If either .dbf or .prj is missing, show a popup alert
            if (!dbfFile || !prjFile) {
                setMissingFiles(true);
            } else {
                setMissingFiles(false);
                // Create a function to read the file as ArrayBuffer and return a promise
                const readFileAsArrayBuffer = (file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                        reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
                    });
                };
    
                let shpData, dbfData;
                // Read both files concurrently using Promise.all
                Promise.all([readFileAsArrayBuffer(shpFile), readFileAsArrayBuffer(dbfFile)])
                    .then((results) => {
                        [shpData, dbfData] = results;
                        // Parse the SHP and DBF data
                        const shpdataParse = parseShp(shpData);
                        const dbfdataParse = parseDbf(dbfData);
                        const geoJsonFeatures = shpdataParse.map((feature, i) => {
                            return {
                                type: "Feature",
                                geometry: {
                                    type: feature.type,
                                    coordinates: feature.coordinates,
                                },
                                properties: {
                                    ...dbfdataParse[i],
                                },
                            };
                        });
                        // Create GeoJSON object
                        const geoJsonData = {
                            type: "FeatureCollection",
                            features: geoJsonFeatures,
                        };
                        console.log(geoJsonData);
                        setFilename(shpFile.name + " & " + dbfFile.name);
                        setData(geoJsonData);
                    })
                    .catch((error) => {
                        console.error("Error reading files:", error);
                    });
            }
        } else if (acceptedFiles.some(file => file.name.endsWith('.json'))) {
            setFileType('json');
            const jsonFile = acceptedFiles.find(file => file.name.endsWith('.json'));
            const reader = new FileReader();
            reader.onload = () => {
                const fileData = JSON.parse(reader.result);
                setData(fileData);
                setFilename(jsonFile.name);
            };
            reader.readAsText(jsonFile);
        } else if (acceptedFiles.some(file => file.name.endsWith('.geojson'))) {
            setFileType('geojson');
            const geojsonFile = acceptedFiles.find(file => file.name.endsWith('.geojson'));
            const reader = new FileReader();
            reader.onload = () => {
                const fileData = JSON.parse(reader.result);
                setData(fileData);
                setFilename(geojsonFile.name);
            };
            reader.readAsText(geojsonFile);
        } else if (acceptedFiles.some(file => file.name.endsWith('.csv'))) {
            setFileType('csv');
            const csvFile = acceptedFiles.find(file => file.name.endsWith('.csv'));
            setFilename(csvFile.name);
    
            // Use Papa Parse to read CSV file
            Papa.parse(csvFile, {
                header: true,
                complete: (results) => {
                    const geoJsonFeatures = results.data.map((row, index) => {
                        // Check if row has the correct number of fields
                        if (Object.keys(row).length < 5) {
                            console.warn(`Row ${index + 1} has missing fields:`, row);
                            return null; // Skip this row
                        }
    
                        try {
                            // Check if the Coordinates field is defined and not empty
                            if (!row.Coordinates || row.Coordinates.trim() === "") {
                                console.warn(`Row ${index + 1}: Coordinates field is missing or empty.`, row);
                                return null; // Skip this row
                            }
    
                            // Parse the coordinates from the string
                            const coordinates = JSON.parse(row.Coordinates);
    
                            // Determine if it's a Point or Polygon based on coordinates length
                            return {
                                type: "Feature",
                                geometry: {
                                    type: Array.isArray(coordinates[0]) ? "Polygon" : "Point",
                                    coordinates: [coordinates],
                                },
                                properties: {
                                    ID: row.ID,
                                    Name: row.Name,
                                    Category: row.Category,
                                    Population: row.Population,
                                },
                            };
                        } catch (error) {
                            console.error(`Error processing row ${index + 1}:`, row, error);
                            return null; // Skip this row
                        }
                    }).filter(feature => feature !== null); // Filter out null features
    
                    const geoJsonData = {
                        type: "FeatureCollection",
                        features: geoJsonFeatures,
                    };
                    console.log(geoJsonData);
                    setData(geoJsonData);
                },
                error: (error) => {
                    console.error("Error parsing CSV:", error);
                },
            });
        } else if (acceptedFiles.some(file => file.name.endsWith('.xlsx'))) {
            setFileType('xlsx');
            const xlsxFile = acceptedFiles.find(file => file.name.endsWith('.xlsx'));
            setFilename(xlsxFile.name);
            const reader = new FileReader();
            reader.onload = (e) => {
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
    
                console.log(jsonData);
                // Process jsonData to create GeoJSON
                const geoJsonFeatures = jsonData.slice(1).map((row, index) => {
                    // Destructure row entries into individual variables
                    const [id, name, category, coordinates, population] = row;
    
                    // Skip empty rows or rows with missing values
                    if (!row || row.length === 0) {
                        return null; // Skip completely empty rows
                    }
    
                    try {
                        // Check if the coordinates field is valid
                        if (!coordinates || typeof coordinates !== 'string' || coordinates.trim() === "") {
                            console.warn(`Row ${index + 1}: Coordinates field is missing or empty.`, row);
                            return null; // Skip rows with invalid or empty coordinate entries
                        }
    
                        // Trim and parse the coordinates string into a JSON object
                        const parsedCoordinates = JSON.parse(coordinates.trim());
    
                        // Ensure that the parsed coordinates are in the expected format for GeoJSON
                        let geoJsonGeometry;
                        if (Array.isArray(parsedCoordinates[0])) {
                            // Assuming coordinates are for a Polygon if the first element is an array
                            geoJsonGeometry = {
                                type: "Polygon",
                                coordinates: [parsedCoordinates], // Wrap in an array to form a Polygon
                            };
                        } else if (Array.isArray(parsedCoordinates) && parsedCoordinates.length === 2) {
                            // Check if the coordinates are a single [longitude, latitude] pair (Point)
                            geoJsonGeometry = {
                                type: "Point",
                                coordinates: parsedCoordinates, // Use as-is for Point geometry
                            };
                        } else {
                            console.warn(`Row ${index + 1}: Invalid coordinate format.`, row);
                            return null; // Skip rows with invalid coordinate format
                        }
    
                        // Create the GeoJSON feature object
                        return {
                            type: "Feature",
                            geometry: geoJsonGeometry,
                            properties: {
                                ID: id,
                                Name: name,
                                Category: category,
                                Population: population,
                            },
                        };
                    } catch (error) {
                        // Handle JSON parsing errors or other exceptions
                        console.error(`Error processing row ${index + 1}:`, row, error);
                        return null; // Skip rows that cause errors
                    }
                }).filter(feature => feature !== null); // Filter out null features
    
                // Create the final GeoJSON feature collection
                const geoJsonData = {
                    type: "FeatureCollection",
                    features: geoJsonFeatures,
                };
    
                console.log(geoJsonData);
                setData(geoJsonData);
            };
            reader.readAsBinaryString(xlsxFile);
        }
    }, []);    

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.json, .geojson, .shp, .dbf, .prj, .csv, .xlsx'
    });

    return (
        <div className='initFileInput'>
            {missingFiles && (
                <div className="popup">
                    <p>Your Shapefile is missing either a .dbf or .prj file. Please upload both for proper functionality.</p>
                    <button onClick={() => setMissingFiles(false)}>Close</button>
                </div>
            )}

            <span className="closeAuth" onClick={closeFileInterface}>
                <IoClose />
            </span>

            {!convertScreen ? (
                <>

                    <>
                        <div className="slide_01">

                            <div className='fileInterfacecontainer'>
                                <div className='leftColumn'>

                                    <div className='coreInstructionBlockContainer'>
                                        <div className="coreInstructionBlockActive">1. Upload</div>
                                        <div className="coreInstructionBlock">2. Convert</div>
                                        <div className="coreInstructionBlock">3. Metrics</div>
                                    </div>

                                    <h1>Upload File</h1>

                                    <div {...getRootProps()} className='dropzone'>
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                                <p>Drop the files here ...</p> :
                                                <p>Drag and drop your file(s) into the box below or<br />click the box to select a file from your computer</p>
                                        }
                                    </div>

                                    <div className="instructions">
                                        <p>File types supported: .json, .geojson, .shp, .dbf, .prj, .csv, .xlsx</p>
                                    </div>

                                    <div style={{
                                        marginTop: '35px', fontSize: '20px', display: 'inline-block'}}>
                                        {filename && <p>Files: {filename}</p>}
                                    </div>

                                    {data && (
                                        <div className='convertInitButton'>
                                            <button className='converterButton' onClick={() => setConvertScreen(true)}>Convert</button>
                                        </div>
                                    )}


                                </div>

                                <div className="rightColumn">
                                    {/* <DisplayDataConvert
                                        globalRefsConverting={globalRefsConverting}
                                        globalStateConverting={globalStateConverting}
                                    /> */}
                                </div>
                            </div>

                        </div>

                    </>

                </>
            ) : (
                <FileToGeoJSONConversion
                    data={data}
                    filename={filename}
                    globalRefs={globalRefs}
                    globalState={globalState}
                    fileType={fileType}
                />
            )
            }
        </div >
    );
};

export default FileInterface;