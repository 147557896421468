import * as THREE from '../../dist/three.module.js';
import mapboxgl from 'mapbox-gl';
import * as TWEEN from '@tweenjs/tween.js';
import { presetColors } from '../../libs/colours.js';
import * as turf from '@turf/turf';

import {
  fmesh,
  fullmesh,
  foundFeature,
  setFoundFeature,
  animate_speed,
  colorMap,
  colorPaletteOptions
} from './globalVariables.js';

let setIsLoading;
let setIsVisible;
let setClickedInfo;
let setClickedInfoHeader;
let longstandingGeoJSONRef;
let selectedMetricValue;
let selectedYearEditing;
let setdropdownhighlightedIndex;
let setIs2DViewActive;
let setStoredFeatureCurrentLandtype;
let isCtrlDown;
let selectedMetric;
let newFeatureDepth;
let setAcknowledgeNewFaetureNewDepth;
let modelCenter;
let pspArray;
let unifiedPspArray = [];
let seenLandtypes;
let hVIPSP;

let dateKey;

let colorPalette;

class SkylarkPolygonLayer {

  type = 'custom';
  renderingMode = '3d';

  // constructor - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  constructor(
    id,
    latitude,
    longitude,
    json,
    globalRefs,
    globalState,
    colorPaletteName,
    parcelDifferentiator
  ) {
    this.id = id;
    this.center = { latitude, longitude };
    this.passedjson = json;
    this.modelTransform = this.calculateModelTransform();
    this.map = globalRefs.mapRef;
    this.colorPaletteName = colorPaletteName;
    this.parcelDifferentiator = parcelDifferentiator;
    setIsLoading = globalState.setIsLoading;
    setIsVisible = globalState.setIsVisible;
    setClickedInfo = globalState.setClickedInfo;
    setClickedInfoHeader = globalState.setClickedInfoHeader;
    longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
    selectedMetricValue = null;
    selectedMetric = globalState.selectedMetric;
    selectedYearEditing = globalState.selectedYearEditing;
    setdropdownhighlightedIndex = globalState.setdropdownhighlightedIndex;
    setIs2DViewActive = globalState.setIs2DViewActive;
    setStoredFeatureCurrentLandtype = globalState.setStoredFeatureCurrentLandtype;
    seenLandtypes = {};
    hVIPSP = 0;
    pspArray = {};
    isCtrlDown = false;
    dateKey = '2024-01-01T00:00:00+00:00';
    setAcknowledgeNewFaetureNewDepth = globalState.setAcknowledgeNewFaetureNewDepth;
  }

  // initial setup functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  async onAdd(map, gl) {
    this.scene = this.makeScene();
    this.checkDifferential(this.passedjson, selectedYearEditing);
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
      logarithmicDepthBuffer: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.autoClear = false;
    this.map = map;
    this.raycaster = new THREE.Raycaster();
    this.raycaster.near = -1;
    this.raycaster.far = 1e10;
  }

  // scene creation relation functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  makeScene() {
    const scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera();
    this.camera.far = 1000000000;
    const skyColor = 0xb1e1ff;
    const groundColor = 0xb97a20;
    this.templight = new THREE.AmbientLight(0xffffff, 0.1);
    scene.add(new THREE.AmbientLight(0xffffff, .6));
    scene.add(new THREE.HemisphereLight(skyColor, groundColor, 0.25));
    let fullMeshPosition = new THREE.Vector3();
    fullmesh.getWorldPosition(fullMeshPosition);
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.15);
    this.directionalLight.position.set(fullMeshPosition.x, fullMeshPosition.z, fullMeshPosition.y + 1000).normalize();
    scene.add(this.directionalLight);
    this.directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.15);
    this.directionalLight2.rotateX = Math.PI / 2;
    this.directionalLight2.position.set(fullMeshPosition.x, fullMeshPosition.z, fullMeshPosition.y - 1000).normalize();
    scene.add(this.directionalLight2);
    return scene;
  }

  // polygon map relation functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  calculateModelTransform() {
    const modelOrigin = [this.center.longitude, this.center.latitude];
    const modelAltitude = 2;
    const modelRotate = [Math.PI / 2, 0, 0];
    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
      modelOrigin,
      modelAltitude
    );
    return {
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z,
      rotateX: modelRotate[0],
      rotateY: modelRotate[1],
      rotateZ: modelRotate[2],
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
    };
  }

  // polygon generation start proceedure - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  checkDifferential = (geojson, selectedYearEditing) => {
    longstandingGeoJSONRef.current = geojson;
    this.gen_psp();
    this.createShapes(longstandingGeoJSONRef.current, selectedYearEditing);
  }

  // generating value sets per landtype per metric to use as baseline shape_area = X of Y metric per landtype - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  gen_psp() {
    let seenLandtypes = new Set();
    const features = longstandingGeoJSONRef.current.features;
    const availableMetrics = new Set();
    for (let i = 0; i < features.length; i++) {
      const timeseries = features[i].feature_data_simple_timeseries;
      if (timeseries) {
        Object.keys(timeseries).forEach(metric => availableMetrics.add(metric));
      }
    }
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      let landtype;
      if (feature.feature_data_simple_timeseries[this.parcelDifferentiator] !== undefined) {
        landtype = feature.feature_data_simple_timeseries[this.parcelDifferentiator][dateKey];
      }
      if (landtype === undefined) {
        continue;
      }
      if (seenLandtypes.has(landtype)) {
        continue;
      }
      availableMetrics.forEach(metric => {
        const selectedFeaturesMetric = feature?.feature_data_simple_timeseries?.[metric]?.[dateKey];
        if (selectedFeaturesMetric === undefined || typeof selectedFeaturesMetric !== 'number') {
          return;
        }
        const landtype_area = feature.feature_data_standard_calculated.Shape_Area;
        let landtype_psp = selectedFeaturesMetric / landtype_area;
        if (landtype_psp === Infinity || isNaN(landtype_psp)) {
          landtype_psp = 0.01;
        }
        unifiedPspArray.push({
          landtype: landtype,
          metric: metric,
          psp: landtype_psp
        });
      });
      seenLandtypes.add(landtype);
    }
    return unifiedPspArray;
  }

  // polygon render functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  filterGeojsonFeatures(geojson, selectedYearEditing) {
    return geojson.features.filter(feature => {
      let deletedDataCut = feature.feature_data_standard.deleted
        ? feature.feature_data_standard.deleted.slice(0, 4)
        : null;
      let selectedYearEditingCut = selectedYearEditing
        ? selectedYearEditing.slice(0, 4)
        : null;
      let createdDataCut = feature.feature_data_standard.created
        ? feature.feature_data_standard.created.slice(0, 4)
        : null;
      if (deletedDataCut <= selectedYearEditingCut && deletedDataCut !== null) {
        return false;
      }
      if (createdDataCut > selectedYearEditingCut) {
        return false;
      }
      return true;
    });
  }

  createShapeFromFeature(feature, selectedYearEditing, modelTransform) {
    const clampLngLat = (coords) => {
      const lng = Math.max(-180, Math.min(180, coords[0]));
      const lat = Math.max(-90, Math.min(90, coords[1]));
      return { lng, lat };
    };
    const createShapeFromCoordinates = (coordinates) => {
      const shapeCoords = coordinates.map(coords => {
        if (isNaN(coords[0]) || isNaN(coords[1])) {
          // console.error('Invalid coordinate detected:', coords);
          return new THREE.Vector2(0, 0); // Default to (0, 0) if invalid
        }
        // Clamp coordinates
        const { lng, lat } = clampLngLat(coords);
        const mercatorCoord = mapboxgl.MercatorCoordinate.fromLngLat({ lng, lat });

        if (isNaN(mercatorCoord.x) || isNaN(mercatorCoord.y)) {
          console.error('Invalid Mercator coordinate:', mercatorCoord);
          return new THREE.Vector2(0, 0);
        }
        // Return transformed coordinates in Three.js space
        return new THREE.Vector2(
          (mercatorCoord.x - modelTransform.translateX) / modelTransform.scale,
          (mercatorCoord.y - modelTransform.translateY) / modelTransform.scale
        );
      });
      // Ensure the shape is closed
      if (!shapeCoords[0].equals(shapeCoords[shapeCoords.length - 1])) {
        shapeCoords.push(shapeCoords[0]);  // Close the polygon
      }
      return new THREE.Shape(shapeCoords);
    };
    // Determine the coordinates based on the feature type
    let coordinates;
    if (feature.feature_data_simple_timeseries.BoundaryChange[selectedYearEditing] !== "000,000") {
      coordinates = JSON.parse(feature.feature_data_simple_timeseries.BoundaryChange[selectedYearEditing]);
    } else {
      coordinates = feature.geometry.coordinates;
    }
    // Handle MultiPolygon or Polygon
    if (feature.geometry.type === 'MultiPolygon') {
      const shapes = [];
      let shape;
      coordinates.forEach(polygonCoords => {
        polygonCoords.forEach(ring => {
          shape = createShapeFromCoordinates(ring); // Assuming you want to use the outer ring
          shapes.push(shape);
        });
        shape = createShapeFromCoordinates(polygonCoords); // Assuming you want to use the outer ring
        shapes.push(shape);
      });
      return shapes; // Return an array of shapes for a MultiPolygon
    } else if (feature.geometry.type === 'Polygon') {
      return createShapeFromCoordinates(coordinates[0]); // Handle single Polygon
    } else {
      console.error('Unsupported geometry type:', feature.geometry.type);
      return null;
    }
  }

  createMeshForFeature(geojson, feature, selectedMetric, selectedYearEditing, modelTransform) {
    const shape = this.createShapeFromFeature(feature, selectedYearEditing, modelTransform);
    const extrudeSettings = { depth: 1, bevelEnabled: false };
    const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
    const selectedMetricValue = feature.feature_data_simple_timeseries[selectedMetric]?.[selectedYearEditing] || 0;
    const differentiatorColorMap = {};
    const uniqueDifferentiators = new Set();
    // Check if parcelDifferentiator is null or empty
    const isParcelDifferentiatorEmpty = !this.parcelDifferentiator || this.parcelDifferentiator === '';
    if (!isParcelDifferentiatorEmpty) {
      // Populate the set of unique differentiators
      geojson.features
        .map(feature => feature.feature_data_simple_timeseries[this.parcelDifferentiator]?.[selectedYearEditing])
        .filter(value => value !== undefined)
        .forEach(value => uniqueDifferentiators.add(value));
    }
    // Define the color palette to use (fallback to default if none is specified)
    colorPalette = colorPaletteOptions[this.colorPaletteName] || colorPaletteOptions.default;
    let colorIndex = 0;
    if (!isParcelDifferentiatorEmpty) {
      // Map differentiators to colors
      uniqueDifferentiators.forEach(differentiator => {
        differentiatorColorMap[differentiator] = colorPalette[colorIndex % colorPalette.length];
        colorIndex++;
      });
    }
    // Get the differentiator value or assign a random pastel color if parcelDifferentiator is empty
    const differentiatorValue = isParcelDifferentiatorEmpty
      ? null
      : feature.feature_data_simple_timeseries[this.parcelDifferentiator]?.[selectedYearEditing];
    // Determine the color to use
    const color = differentiatorValue
      ? new THREE.Color(differentiatorColorMap[differentiatorValue] || colorPalette[0]) // Use mapped color if differentiator exists
      : new THREE.Color(
        0.6 + Math.random() * 0.5, // Red component, adjusted for pastel shade
        0.6 + Math.random() * 0.5, // Green component, adjusted for pastel shade
        0.6 + Math.random() * 0.5  // Blue component, adjusted for pastel shade
      ); // Assign random pastel color if differentiator is empty
    const minOffset = 20;
    const maxOffset = 30;
    const material = new THREE.MeshPhongMaterial({
      color: color,
      opacity: 1,
      // blending: THREE.NormalBlending,
      transparent: false,
      // opacity: 0.6 // Adjust for desired transparency
      polygonOffset: true,
      polygonOffsetFactor: minOffset + Math.random() * (maxOffset - minOffset), // Random value between 2 and 3
      polygonOffsetUnits: minOffset + Math.random() * (maxOffset - minOffset),  // Random value between 2 and 3
      // polygonOffsetFactor: -1,
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.renderOrder = Math.random() * 10;
    mesh.name = feature.skylark_id;
    mesh.scale.z = -selectedMetricValue * 1 || 0.01;
    return mesh;
  }

  createShapes(geojson, selectedYearEditing) {
    let filteredGeojson = { type: "FeatureCollection", features: this.filterGeojsonFeatures(geojson, selectedYearEditing) };
    this.modelTransform = this.calculateModelTransform();
    // Reset fmesh position
    fmesh.position.set(0, 0, 0);
    // Calculate bounding box using Turf.js
    const bbox = turf.bbox(filteredGeojson); // [west, south, east, north]
    const geoWidth = bbox[2] - bbox[0];  // East - West
    const geoHeight = bbox[3] - bbox[1]; // North - South
    // Define max height as a percentage of the smaller dimension
    const maxHeightPercentage = 20000; // 10% of the smaller dimension
    const maxHeight = Math.min(geoWidth, geoHeight) * maxHeightPercentage; // Calculate maximum height
    // Find the maximum metric value for scaling
    const maxMetricValue = Math.max(...filteredGeojson.features.map(feature => feature.feature_data_simple_timeseries[selectedMetric]?.[selectedYearEditing] || 0));
    // Add meshes for each feature in filteredGeojson
    filteredGeojson.features.forEach(feature => {
      const metricValue = feature.feature_data_simple_timeseries[selectedMetric]?.[selectedYearEditing] || 0;
      const height = this.calculateHeight(metricValue, maxMetricValue, maxHeight);
      const mesh = this.createMeshForFeature(geojson, feature, selectedMetric, selectedYearEditing, this.modelTransform);
      mesh.scale.set(1, 1, -height); // Scale the mesh by the calculated height
      fmesh.add(mesh);
    });
    // Compute the bounding box and center for fmesh
    const box = new THREE.Box3().setFromObject(fmesh);
    const modelCenter = box.getCenter(new THREE.Vector3());
    // Add fmesh to fullmesh and set initial scaling
    fullmesh.add(fmesh);
    fullmesh.rotation.x = Math.PI / 2;
    // Add fullmesh to the scene
    this.scene.add(fullmesh);
    // Adjust position based on model center
    fmesh.position.set(-modelCenter.x, -modelCenter.y);
    // Perform map repaint and animations
    fmesh.scale.z = 0.001;
    this.map_repaint();
    this.init_animation();
    // Reset position and finalize adjustments
    fmesh.position.set(0, 0, 0);
  }

  calculateHeight(metricValue, maxMetricValue, maxHeight) {
    if (maxMetricValue === 0) return 0; // Avoid division by zero
    const normalizedValue = metricValue / maxMetricValue; // Normalize based on max metric
    return normalizedValue * maxHeight; // Scale to the maximum height
  }

  // remove current scene functions for adding new drawing instance only - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  removeDrawFromScene() {
    this.scene.remove(fullmesh);
  }

  adjustFeatureOpacitiesPerDifferentiator(landtype) {
    if (landtype === "reset") {
      this.resetfeatureOpacities(fmesh);
      this.map_repaint();
      return;
    }
    fmesh.children.forEach((child) => {
      const skylarkId = child.name;
      const feature = longstandingGeoJSONRef.current.features.find(
        (f) => f.skylark_id === skylarkId
      );
      if (feature && landtype.includes(feature.feature_data_simple_timeseries[this.parcelDifferentiator]?.[selectedYearEditing])) {
        child.material.opacity = 1;
        child.material.needsUpdate = true;
      } else {
        child.material.opacity = 0.4;
        child.material.transparent = true;
        child.material.needsUpdate = true;
      }
    });
    this.map_repaint();
    if (landtype.length === 0) {
      fmesh.children.forEach((child) => {
        const skylarkId = child.name;
        const feature = longstandingGeoJSONRef.current.features.find(
          (f) => f.skylark_id === skylarkId
        );
        if (feature) {
          child.visible = true;
        }
      });
      this.map_repaint();
    }
  }

  // init animation - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  init_animation = () => {
    setIsLoading(false);
    this.map_repaint();
    setTimeout(() => {
      new TWEEN.Tween(fmesh.scale)
        .to({ z: 1 }, animate_speed)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        .onComplete(() => {
          setIsLoading(false);
        });
    }, animate_speed);
  }

  zoomBased_Animate_Larger = (zoomLevel) => {
    this.map_repaint();
    if (zoomLevel < 4) {
      console.log('Zoom Out Increase Polygons');
      new TWEEN.Tween(fmesh.scale)
        .to({ z: 100 }, animate_speed)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        .onComplete(() => {
          setIsLoading(false);
        });
    } else {
      console.log('Zoom In Decrease Polygons');
      new TWEEN.Tween(fmesh.scale)
        .to({ z: 1 }, animate_speed)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        .onComplete(() => {
          setIsLoading(false);
        });
    }
  }

  // is land use function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  setEnableLandUse = () => {
    this.isLandUse = true;
  }

  // is land use function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  setDisableLandUse = () => {
    this.isLandUse = false;
  }

  // is pie chart function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  serEnablePieChart = () => {
    this.isPieChart = true;
  }

  // is land use function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  setDisablePieChart = () => {
    this.isPieChart = false;
  }

  // control button down function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  controlDown = () => {
    isCtrlDown = true;
  }

  // control button up function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  controlUp = () => {
    isCtrlDown = false;
  }

  // polygon selection functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  raycast(point, selectedYearEditing) {
    if (isCtrlDown) {
      return;
    }
    let mouse = new THREE.Vector2();
    mouse.x = (point.x / this.map.transform.width) * 2 - 1;
    mouse.y = 1 - (point.y / this.map.transform.height) * 2;
    const camInverseProjection = new THREE.Matrix4().getInverse(this.camera.projectionMatrix);
    const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection);
    const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(camInverseProjection);
    const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();
    this.raycaster.set(cameraPosition, viewDirection);
    let intersects = this.raycaster.intersectObjects(this.scene.children, true);
    if (intersects.length > 0) {
      this.search_by_id(intersects[0].object.name, selectedYearEditing);
      console.log(intersects[0].object.name);
    } else {
      setIsVisible(false);
      this.resetfeatureOpacities(fmesh);
    }
  }

  // if created a new polygon in draw tool - select that one specifically for changing landtype of - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  createdNewFeatures = (newFeature, selectedYearEditing) => {
    this.search_by_id(newFeature, selectedYearEditing);
  };

  // polygon info functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  search_by_id = (skylark_id, selectedYear) => {
    for (const feature of longstandingGeoJSONRef.current.features) {
      if (feature.skylark_id === skylark_id) {
        setFoundFeature(feature);
        // setStoredFeatureCurrentLandtype(feature.feature_data_simple_timeseries.HabitatType[selectedYear]);
        break;
      }
    }
    this.clickedInfoPopup();
    return foundFeature.properties;
  };

  // polygon info functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  clickedInfoPopup = () => {
    setIsVisible(true);
    let color;
    this.adjustFeatureOpacities(fmesh, foundFeature);
    const skylark_id = foundFeature.skylark_id;
    console.log(skylark_id)
    const propertiesHTMLHeader = `${skylark_id}`;
    setClickedInfoHeader(propertiesHTMLHeader);
  };

  // Assuming foundFeature is the feature found and want to highlight - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  adjustFeatureOpacities(fmesh, foundFeature) {
    fmesh.traverse((child) => {
      if (child.isMesh) { // Ensure the child is a mesh
        if (foundFeature && child.name === foundFeature.skylark_id) {
          child.material.opacity = 1;
          child.material.needsUpdate = true;
        } else {
          child.material.opacity = 0.4;
          child.material.transparent = true;
          child.material.needsUpdate = true;
        }
      }
    });
  }

  // Utility function to repaint the map - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  resetfeatureOpacities(fmesh) {
    fmesh.traverse((child) => {
      if (child.isMesh) {
        child.material.opacity = 1;
        child.material.needsUpdate = true;
      }
    });
    this.map_repaint();
  }

  // polygon update function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  handleCloseClickedInfo = (globalLandtype, selectedYearEditing) => {
    const interfeatures = longstandingGeoJSONRef.current.features;
    const currentSelectedYear = selectedYearEditing;
    newFeatureDepth = this.calculateAllMetric(foundFeature, globalLandtype);
    let newFeatureDepthSelectedMetric = newFeatureDepth[selectedMetric];
    setAcknowledgeNewFaetureNewDepth(newFeatureDepth);
    this.animate_change(foundFeature, newFeatureDepthSelectedMetric);
    this.updateJson(interfeatures, globalLandtype, currentSelectedYear, newFeatureDepth);
    setClickedInfo(null);
    this.map_repaint();
    setdropdownhighlightedIndex(null);
    return null;
  };

  // polygon info close functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  handleCloseClickedInfoButton = () => {
    this.resetfeatureOpacities(fmesh);
    setIsVisible(false);
    setClickedInfo(null);
    this.map_repaint();
    setdropdownhighlightedIndex(null);
    return null;
  };

  // polygon info close cancel functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  handleCloseClickedInfoCancel = () => {
    this.resetfeatureOpacities(fmesh);
    setIsVisible(false);
    setClickedInfo(null);
    this.map_repaint();
    setdropdownhighlightedIndex(null);
    return null;
  };

  // update the geojson following the change of landtype - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  updateJson = (interfeatures, globalLandtype, currentSelectedYear, newFeatureDepth) => {
    const newFeature = { ...foundFeature };
    const updatedHabitatType = { ...newFeature.feature_data_simple_timeseries[this.parcelDifferentiator] };
    const years = Object.keys(updatedHabitatType).sort();
    let shouldUpdate = false;
    for (const year of years) {
      if (year === currentSelectedYear) {
        shouldUpdate = true;
      }
      if (shouldUpdate) {
        updatedHabitatType[year] = globalLandtype;
      }
    }
    const updatedTimeseries = { ...newFeature.feature_data_simple_timeseries };
    for (const year of years) {
      if (year >= currentSelectedYear) {
        updatedTimeseries[this.parcelDifferentiator][year] = globalLandtype;
        for (const [metric, value] of Object.entries(newFeatureDepth)) {
          updatedTimeseries[metric] = updatedTimeseries[metric] || {};
          updatedTimeseries[metric][year] = value;
        }
      }
    }
    newFeature.feature_data_simple_timeseries = updatedTimeseries;
    const updatedFeatures = interfeatures.map(feature => {
      if (feature.skylark_id === foundFeature.skylark_id) {
        return newFeature;
      }
      return feature;
    });
    longstandingGeoJSONRef.current.features = updatedFeatures;
  };

  // dropdown list carbon change function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  calculateAllMetric = (feature, selectedValue) => {
    let selectedMetricAmountObject = {};
    const shapeArea = feature.feature_data_standard_calculated.Shape_Area;
    unifiedPspArray.forEach(entry => {
      if (entry.landtype === selectedValue) {
        let pspValue = entry.psp;
        let metric = entry.metric;
        if (typeof pspValue === 'number' && !isNaN(pspValue)) {
          let calculatedValue = pspValue * shapeArea;
          selectedMetricAmountObject[metric] = calculatedValue;
        }
      }
    });
    return selectedMetricAmountObject;
  };

  // update polygon color function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  getColorForFeature = (feature, selectedYearEditing) => {
    let landTypeforcolor = "";
    landTypeforcolor = feature.feature_data_simple_timeseries.HabitatType[selectedYearEditing];
    const colorHex = colorMap[landTypeforcolor] || this.updateColor(feature, landTypeforcolor);
    return colorHex;
  };

  // polygon color update functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  updateColor = (feature, landType) => {
    if (landType === undefined || landType === null) {
    } else {
      landType = landType.toLowerCase();
      const colorHex = presetColors[landType] || '#FFFFFF';
      const updatedColor = new THREE.Color(colorHex);
      let found = false;
      fmesh.traverse((child) => {
        if (child.name === feature.skylark_id) {
          child.material.color.copy(updatedColor);
          found = true;
        }
      });
      if (found) {
        colorMap[landType] = colorHex;
      }
      return colorHex;
    }
  };

  // polygon animation function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  animate_change = (feature, growth) => {
    this.map_repaint();
    fmesh.traverse((child) => {
      if (child.name === feature.skylark_id) {
        new TWEEN.Tween(child.scale)
          .to({ z: - growth }, animate_speed)
          .easing(TWEEN.Easing.Cubic.InOut)
          .onUpdate(() => {
          })
          .start();
      }
    });
  };

  // camera fly to function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  cameraFlyToLatLong = (latitude, longitude) => {
    this.map.flyTo({
      center: [longitude, latitude],
      zoom: 15,
      essential: true,
      speed: 3
    });
  };

  // camera fly to north - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  cameraFlyToNorth = (latitude, longitude) => {
    this.map.flyTo({
      bearing: 0,
      essential: true,
      speed: 3
    });
  };

  // swap map base function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  swapMapBase = (style) => {
    if (style === 'Satellite') {
      this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'visible');
    }
    if (style === 'Map') {
      this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'none');
    }
  }

  // 2d view - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  set2DView() {
    setIsLoading(false);
    setIs2DViewActive(true);
    setTimeout(() => {
      new TWEEN.Tween(fmesh.scale)
        .to({ z: 0.01 }, animate_speed)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        .onComplete(() => {
          setIsLoading(false);
        });
    }, animate_speed);
    this.scene.add(this.templight);
    this.map.flyTo({
      zoom: 5,
      essential: true,
      speed: .2,
      pitch: 0
    });
  }

  // 2d view - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  set2DViewBoundary() {
    setIsLoading(false);
    setIs2DViewActive(true);
    setTimeout(() => {
      new TWEEN.Tween(fmesh.scale)
        .to({ z: 0.01 }, animate_speed)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        .onComplete(() => {
          setIsLoading(false);
        });
    }, animate_speed);
    this.map.flyTo({
      zoom: 13,
      essential: true,
      speed: .2,
      pitch: 0
    });
  }

  // 3d view - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  set3DView(selectedYearEditing) {
    setIsLoading(false);
    setIs2DViewActive(false);
    // this.addRegularColours(selectedYearEditing);
    setTimeout(() => {
      new TWEEN.Tween(fmesh.scale)
        .to({ z: 1 }, animate_speed)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        .onComplete(() => {
          setIsLoading(false);
        });
    }, animate_speed);
    this.scene.remove(this.templight);
    this.map.flyTo({
      zoom: 5,
      essential: true,
      speed: .2,
      pitch: 60
    });
  }

  // add regular colours - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  addRegularColours(selectedYearEditing) {
    fmesh.traverse((child) => {
      const skylark_id = child.name;
      const feature = longstandingGeoJSONRef.current.features.find(
        (f) => f.skylark_id === skylark_id
      );
      if (feature) {
        const color = this.getColorForFeature(feature, selectedYearEditing);
        const material = new THREE.MeshPhongMaterial({ color: color, opacity: 1 });
        child.material = material;
      }
    });
    this.map_repaint();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  // set landtype function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  setLandtype(landtypes) {
    fmesh.children.forEach((child) => {
      if (landtypes === "Hide all") {
        child.visible = false;
      }
      const skylarkId = child.name;
      const feature = longstandingGeoJSONRef.current.features.find(
        (f) => f.skylark_id === skylarkId
      );
      if (feature && landtypes.includes(feature.feature_data_simple_timeseries[this.parcelDifferentiator]?.[selectedYearEditing])) {
        child.visible = true;
      } else {
        child.visible = false;
      }
    });
    this.map_repaint();
    if (landtypes.length === 0) {
      fmesh.children.forEach((child) => {
        const skylarkId = child.name;
        const feature = longstandingGeoJSONRef.current.features.find(
          (f) => f.skylark_id === skylarkId
        );
        if (feature) {
          child.visible = true;
        }
      });
      this.map_repaint();
    }
  }

  // map repaint function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  map_repaint = () => {
    let id = setInterval(() => {
      this.map.triggerRepaint();
    }, 1);
    setTimeout(() => {
      clearInterval(id);
    }, animate_speed * 2);
  }

  // render function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  render(gl, matrix) {
    TWEEN.update();
    const rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      this.modelTransform.rotateX
    );
    const rotationY = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 1, 0),
      this.modelTransform.rotateY
    );
    const rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      this.modelTransform.rotateZ
    );
    const m = new THREE.Matrix4().fromArray(matrix);
    const l = new THREE.Matrix4()
      .makeTranslation(
        this.modelTransform.translateX,
        this.modelTransform.translateY,
        this.modelTransform.translateZ
      )
      .scale(
        new THREE.Vector3(
          this.modelTransform.scale,
          -this.modelTransform.scale,
          this.modelTransform.scale
        )
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ);
    this.camera.projectionMatrix = m.multiply(l);
    this.renderer.state.reset();
    this.renderer.render(this.scene, this.camera);

  }

}

export default SkylarkPolygonLayer;