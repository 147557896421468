import React, { useEffect, useState } from 'react';

const MetricDropdown = ({
  setSelectedMetric,
  selectedMetric,
  globalState,
  ResetMap,
  globalRefs,
  selectedYearEditing,
}) => {
  const [clicked, setClicked] = useState(false);

  const { isModifying, isViewingConvertedData } = globalState;
  const longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;

  const convertedMetricOptions = [];

  let universalParcelDifferentiator = globalState.universalParcelDifferentiator;
  let setUniversalParcelDifferentiator = globalState.setUniversalParcelDifferentiator;
  let setIsVisible = globalState.setIsVisible;
  let isVisible = globalState.isVisible;

  const handleOptionClick = (selectedValue) => {
    setSelectedMetric(selectedValue);
    setClicked(true);
    if (isVisible) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    ResetMap(globalRefs, globalState);
  }, [selectedMetric, clicked]);

  if (longstandingGeoJSONRef.current) {
    longstandingGeoJSONRef.current.features.forEach((feature) => {
      if (feature.feature_data_simple_timeseries) {
        Object.keys(feature.feature_data_simple_timeseries).forEach((key) => {
          const firstDateValue = feature.feature_data_simple_timeseries[key][selectedYearEditing];
          if (typeof firstDateValue === 'number' && !convertedMetricOptions.includes(key)) {
            convertedMetricOptions.push(key);
          }
        });
      }
    });
  }

  const optionsToDisplay = convertedMetricOptions.map((option) => ({
    value: option,
    label: option
  }));
  // Automatically select the first option if available
  const defaultOptionValue = optionsToDisplay.length > 0 ? optionsToDisplay[0].value : "";
  if (!selectedMetric) {
    console.log(universalParcelDifferentiator)
    setSelectedMetric(defaultOptionValue);
  }

  return (
    <div className='select-container'>
      <select
        className='metricSelectionModify'
        onChange={(e) => handleOptionClick(e.target.value)}
        defaultValue={defaultOptionValue} // Set the default selected value
      >
        <option value="" disabled>Select Metric</option>
        {optionsToDisplay.map((option) => (
          <option key={option.value} value={option.value} disabled={isModifying}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MetricDropdown;
