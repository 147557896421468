import React from 'react';
import { useState } from 'react';
import { MdAccountCircle } from "react-icons/md";
import logo from '../../images/logo.png';
import { BrowserView, MobileView } from 'react-device-detect';
import { Squeeze as Hamburger } from 'hamburger-react'

const TopMenu = ({
  authClicked,
  openAboutPanel,
  openPrivacyPanel,
  openTacPanel,
  openMobileSignIn,
  globalState
}) => {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);

  let userEmail = globalState.userEmail;
  let loggedInToAPI = globalState.loggedInToAPI;

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setOpen(!isOpen);
  };

  return (
    <>
      <div className='top_menu'>

        <div className='title'>
          <img src={logo} className='logo' alt='Skylark Logo' />
        </div>

        <BrowserView>
          {loggedInToAPI ?
            <div className='auth' onClick={authClicked}>
              <p>{userEmail}</p>
              <div className="authAccountButton"><MdAccountCircle /></div>
            </div>
            :
            <div className='auth' onClick={authClicked}>
              <p>Sign In</p>
              <div className="authAccountButton"><MdAccountCircle /></div>
            </div>
          }
          <div className='menuItems'>
            <div onClick={openAboutPanel} className='menuItem'>About Harrier</div>
            <div onClick={openPrivacyPanel} className='menuItem'>Privacy</div>
            <div onClick={openTacPanel} className='menuItem'>Terms & Conditions</div>
          </div>
        </BrowserView>

        <MobileView>
          <div className='burgermenu' onClick={toggleMobileMenu}>
            <Hamburger className='burgermenuinner' color="#29645D" size="20" toggled={isOpen} toggle={setOpen} />
          </div>
        </MobileView>

      </div>
      {mobileMenuOpen && (
        <div className="top_menu_Mobile">
          <div onClick={openMobileSignIn} className='menuItemMobile'>Account</div>
          <div onClick={openAboutPanel} className='menuItemMobile'>About Skylark</div>
          <div onClick={openPrivacyPanel} className='menuItemMobile'>Privacy</div>
          <div onClick={openTacPanel} className='menuItemMobile'>Terms & Conditions</div>
        </div>
      )}
    </>
  );
};

export default TopMenu;