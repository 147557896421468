export const presetColors = {
    "cropland": "#F58941",
    "arable and horticulture": "#FFDAB9",
    "arable field margins": "#FFDAB9",
    "temporary grasslands and clover leys": "#F69858",
    "cereal crops": "#FFDAB9",
    "non-cereal crops": "#F6BA85",
    "intensive orchards": "#98FB98",
    "horticulture": "#AFEEEE",
    "urban": "#FFC0CB",
    "built-up areas and gardens": "#FFC0CB",
    "open mosaic undeveloped land": "#D8BFD8",
    "developed, bare sealed surface": "#FFC0CB",
    "buildings": "#FFC0CB",
    "other developed land": "#FFC0CB",
    "artificial unvegetated sealed surface": "#DF687B",
    "suburban natural surface": "#E86570",
    "build linear features": "#ED2B52",
    "grassland": "#BDFCC9",
    "acid grassland": "#98FB98",
    "lowland dry acid grassland": "#98FB98",
    "upland acid grassland": "#98FB98",
    "montane acid grasslands": "#98FB98",
    "other upland acid grassland": "#98FB98",
    "bracken": "#98FB98",
    "calcareous grassland": "#98FB98",
    "lowland calcareous grassland": "#98FB98",
    "upland calcareous grassland": "#98FB98",
    "neutral grassland": "#98FB98",
    "lowland meadows": "#98FB98",
    "upland hay meadows": "#98FB98",
    "other neutral grassland": "#98FB98",
    "Arrhenatherum neutral grassland": "#98FB98",
    "Lolium-Cynodon neutral grassland": "#98FB98",
    "Deschampsia neutral grassland": "#98FB98",
    "Holcus-Juncus neutral grassland": "#98FB98",
    "modified grassland": "#BDFCC9",
    "heathland and shrub": "#98FB98",
    "dwarf shrub heath": "#98FB98",
    "lowland heath": "#98FB98",
    "dry heaths, lowland": "#98FB98",
    "wet heaths, lowland": "#98FB98",
    "upland heath": "#98FB98",
    "dry heaths, upland": "#98FB98",
    "wet heaths, upland": "#98FB98",
    "hedgerow": "#98FB98",
    "hedgerow, priority habitat": "#98FB98",
    "other hedgerows": "#98FB98",
    "dense shrub": "#8273B2",
    "wetland": "#ADD8E6",
    "bog": "#ADD8E6",
    "blanket bog": "#ADD8E6",
    "lowland raised bog": "#ADD8E6",
    "fen, march, and swamp": "#ADD8E6",
    "lowland fens": "#ADD8E6",
    "aquatic minimal vegetation": "#ADD8E6",
    "reedbeds": "#ADD8E6",
    "sparsely vegetated land": "#E6E6FA",
    "inland rock": "#CDCD00",
    "inland rock scree habitats": "#CDCD00",
    "other inland rock scree": "#CDCD00",
    "supralittoral rock": "#CDCD00",
    "maritime cliff and slopes": "#CDCD00",
    "supralittoral sediment": "#D4D4D0",
    "coastal sand dunes": "#CDCD00",
    "coastal vegetated shingle": "#CDCD00",
    "rivers and lakes": "#ADD8E6",
    "standing water": "#ADD8E6",
    "eutrophic standing waters": "#ADD8E6",
    "canals": "#ADD8E6",
    "rivers and streams": "#ADD8E6",
    "marine transitional waters": "#ADD8E6",
    "littoral rock": "#ADD8E6",
    "littoral sediment": "#ADD8E6",
    "coastal saltmarsh": "#ADD8E6",
    "intertidal mudflats": "#ADD8E6",
    "woodland": "#AFEEEE",
    "broadleave…yew woodland": "#AFEEEE",
    "upland oakwood": "#AFEEEE",
    "upland mixed ashwoods": "#6EB97B",
    "lowland beechwood": "#AFEEEE",
    "wet woodland": "#AFEEEE",
    "upland birchwoods": "#AFEEEE",
    "lowland mixed deciduous woodland": "#AFEEEE",
    "other woodland mixed": "#71904E",
    "coniferous woodland": "#F5DEB3",
    "native pine woodland": "#F5DEB3",
    "other scots pine woodland": "#F5DEB3",
    "other coniferous woodland": "#F9F39D",
    "line of trees": "#B0C4DE",
    // 
    "rural": "#98FB98",
    "urban": "#FFC0CB",
    "coastal": "#ADD8E6",
  }
  
  
