import * as THREE from '../../dist/three.module.js';
import mapboxgl from 'mapbox-gl';
import * as TWEEN from '@tweenjs/tween.js';

import {
  fmesh,
  fullmesh,
} from './globalVariablesConverting.js';

let longstandingGeoJSONRef;
let createShapesInit = 0;
let modelCenter;
let colorPalette;

class SkylarkPolygonLayer {
  type = 'custom';
  renderingMode = '3d';

  // constructor - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  constructor(id, latitude, longitude, json, globalRefs, globalState, addMapBoxLayerBool, parcelDifferentiator, colorPaletteName) {
    this.id = id;
    this.center = { latitude, longitude };
    this.passedjson = json;
    this.modelTransform = this.calculateModelTransform();
    this.map = globalRefs.mapRef;
    this.parcelDifferentiator = parcelDifferentiator;
    this.addMapBoxLayerBool = addMapBoxLayerBool;
    this.colorPaletteName = colorPaletteName;
    longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  }

  // initial setup functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  async onAdd(map, gl) {
    this.scene = this.makeScene();
    longstandingGeoJSONRef.current = this.passedjson;
    this.createShapes(longstandingGeoJSONRef.current);
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
      logarithmicDepthBuffer: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.autoClear = false;
    this.map = map;
  }

  // scene creation relation functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  makeScene() {
    const scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera();
    this.camera.far = 1000000000;
    const skyColor = 0xb1e1ff;
    const groundColor = 0xb97a20;
    this.templight = new THREE.AmbientLight(0xffffff, 0.1);
    scene.add(new THREE.AmbientLight(0xffffff, .6));
    return scene;
  }

  // polygon map relation functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  calculateModelTransform() {
    const modelOrigin = [this.center.longitude, this.center.latitude];
    const modelAltitude = 2;
    const modelRotate = [Math.PI / 2, 0, 0];
    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
      modelOrigin,
      modelAltitude
    );
    return {
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z,
      rotateX: modelRotate[0],
      rotateY: modelRotate[1],
      rotateZ: modelRotate[2],
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
    };
  }

  // polygon render functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  createShapes(geojson) {
    let filteredGeojson;
    filteredGeojson = geojson;
    this.modelTransform = "";
    fmesh.position.set(0, 0, 0);
    this.modelTransform = this.calculateModelTransform();
    const differentiatorKey = this.parcelDifferentiator;
    const uniqueDifferentiators = new Set(
      filteredGeojson.features.map(feature => feature.properties[differentiatorKey])
    );
    const differentiatorColorMap = {};
    if (this.colorPaletteName === 'default') {
      colorPalette = ['#006400', '#228B22', '#32CD32', '#90EE90', '#98FB98', '#00FF00']
    } else if (this.colorPaletteName === 'land') {
      colorPalette = ['#006400', '#228B22', '#32CD32', '#90EE90', '#98FB98', '#00FF00']
    } else if (this.colorPaletteName === 'coastal') {
      colorPalette = ['#FFA500', '#FF8C00', '#FF7F50', '#FF6347', '#FF4500', '#FFD700']
    } else if (this.colorPaletteName === 'mixed') {
      colorPalette = ['#FF1493', '#00BFFF', '#7FFF00', '#FF6347', '#DAA520', '#ADFF2F']
    } else if (this.colorPaletteName === 'autumn') {
      colorPalette = ['#FF0000', '#008000', '#FF6347', '#006400', '#FF4500', '#228B22']
    } else if (this.colorPaletteName === 'chemical') {
      colorPalette = ['#FFC0CB', '#FF69B4', '#FF1493', '#FFB6C1', '#FF77FF', '#FF00FF']
    } else if (this.colorPaletteName === 'water') {
      colorPalette = ['#0000FF', '#4682B4', '#87CEEB', '#5F9EA0', '#00BFFF', '#1E90FF']
    }
    let colorIndex = 0;
    uniqueDifferentiators.forEach(differentiator => {
      differentiatorColorMap[differentiator] = colorPalette[colorIndex % colorPalette.length];
      colorIndex++;
    });
    filteredGeojson.features.map((feature) => {
      let coordinates;
      if (feature.geometry.type === 'Polygon') {
        coordinates = feature.geometry.coordinates[0];
      } else if (feature.geometry.type === 'MultiPolygon') {
        coordinates = feature.geometry.coordinates[0][0];
      }
      const shape = this.createShapeFromFeature(feature, this.modelTransform);
      let extrudeSettings = { depth: 1, bevelEnabled: false };
      const differentiatorValue = feature.properties[differentiatorKey] || 'default';
      let color;
      if (this.parcelDifferentiator === null) {
        color = new THREE.Color('#ff0000');
      } else {
        color = new THREE.Color(differentiatorColorMap[differentiatorValue]);
      }
      const material = new THREE.MeshPhongMaterial({
        color: color,
        opacity: 0.6
      });
      const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
      const mesh = new THREE.Mesh(geometry, material);
      mesh.name = feature.skylark_id;
      mesh.scale.z = 1;
      fmesh.add(mesh);
    });
    const box = new THREE.Box3().setFromObject(fmesh);
    if (createShapesInit === 0) {
      modelCenter = box.getCenter(new THREE.Vector3());
      createShapesInit = 1;
    }
    fullmesh.add(fmesh);
    fmesh.scale.set(1, 1, 1);
    fullmesh.rotation.x = Math.PI / 2;
    fmesh.position.set(-modelCenter.x, -modelCenter.y, 0);
    this.scene.add(fullmesh);
    this.map_repaint();
    if (this.addMapBoxLayerBool) {
      this.addMapboxLayer();
    }
    fmesh.position.set(0, 0, 0);
  }

  createShapeFromFeature(feature, modelTransform) {
    const clampLngLat = (coords) => {
      const lng = Math.max(-180, Math.min(180, coords[0]));
      const lat = Math.max(-90, Math.min(90, coords[1]));
      return { lng, lat };
    };
    const createShapeFromCoordinates = (coordinates) => {
      const shapeCoords = coordinates.map(coords => {
        if (isNaN(coords[0]) || isNaN(coords[1])) {
          console.error('Invalid coordinate detected:', coords);
          return new THREE.Vector2(0, 0); // Default to (0, 0) if invalid
        }
        // Clamp coordinates
        const { lng, lat } = clampLngLat(coords);
        const mercatorCoord = mapboxgl.MercatorCoordinate.fromLngLat({ lng, lat });

        if (isNaN(mercatorCoord.x) || isNaN(mercatorCoord.y)) {
          console.error('Invalid Mercator coordinate:', mercatorCoord);
          return new THREE.Vector2(0, 0);
        }
        // Return transformed coordinates in Three.js space
        return new THREE.Vector2(
          (mercatorCoord.x - modelTransform.translateX) / modelTransform.scale,
          (mercatorCoord.y - modelTransform.translateY) / modelTransform.scale
        );
      });
      // Ensure the shape is closed
      if (!shapeCoords[0].equals(shapeCoords[shapeCoords.length - 1])) {
        shapeCoords.push(shapeCoords[0]);  // Close the polygon
      }
      return new THREE.Shape(shapeCoords);
    };
    // Determine the coordinates based on the feature type
    let coordinates;
    coordinates = feature.geometry.coordinates;
    // Handle MultiPolygon or Polygon
    if (feature.geometry.type === 'MultiPolygon') {
      const shapes = [];
      let shape;
      coordinates.forEach(polygonCoords => {
        polygonCoords.forEach(ring => {
          shape = createShapeFromCoordinates(ring); // Assuming you want to use the outer ring
          shapes.push(shape);
        });
        shape = createShapeFromCoordinates(polygonCoords); // Assuming you want to use the outer ring
        shapes.push(shape);
      });
      return shapes; // Return an array of shapes for a MultiPolygon
    } else if (feature.geometry.type === 'Polygon') {
      return createShapeFromCoordinates(coordinates[0]); // Handle single Polygon
    } else {
      console.error('Unsupported geometry type:', feature.geometry.type);
      return null;
    }
  }

  addMapboxLayer() {
    if (this.map.current.getLayer('reprojected-layer')) {
      this.map.current.removeLayer('reprojected-layer');
      this.map.current.removeSource('reprojected-data');
    }
    this.map.current.addSource('reprojected-data', {
      type: 'geojson',
      data: longstandingGeoJSONRef.current,
    });
    this.map.current.addLayer({
      id: 'reprojected-layer',
      type: 'fill',
      source: 'reprojected-data',
      paint: {
        'fill-color': '#0000ff',
        'fill-opacity': 0.4,
      },
    });
  }

  // map repaint function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  map_repaint = () => {
    let id = setInterval(() => {
      this.map.triggerRepaint();
    }, 1);
    setTimeout(() => {
      clearInterval(id);
    });
  }

  // render function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  render(gl, matrix) {
    TWEEN.update();
    const rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      this.modelTransform.rotateX
    );
    const rotationY = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 1, 0),
      this.modelTransform.rotateY
    );
    const rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      this.modelTransform.rotateZ
    );
    const m = new THREE.Matrix4().fromArray(matrix);
    const l = new THREE.Matrix4()
      .makeTranslation(
        this.modelTransform.translateX,
        this.modelTransform.translateY,
        this.modelTransform.translateZ
      )
      .scale(
        new THREE.Vector3(
          this.modelTransform.scale,
          -this.modelTransform.scale,
          this.modelTransform.scale
        )
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ);
    this.camera.projectionMatrix = m.multiply(l);
    this.renderer.state.reset();
    this.renderer.render(this.scene, this.camera);
  }
}

export default SkylarkPolygonLayer;