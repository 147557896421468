import React, { useEffect, useState } from 'react';

const MetricDropdown = ({
    setSelectedMetric,
    selectedMetric,
    globalState,
    ResetMap,
    globalRefs,
    selectedYearEditing,
}) => {

    let globalPublicSelectedDataset = globalState.globalPublicSelectedDataset;
    let setGlobalPublicSelectedDataset = globalState.setGlobalPublicSelectedDataset;

    const handleOptionClick = (value) => {
        setGlobalPublicSelectedDataset(value);
    }

    return (
        <div className='select-container'>
            <select
                className='metricSelectionModify'
                onChange={(e) => handleOptionClick(e.target.value)}
            >
                <option value="" disabled>Select Data</option>
                <option value="UnitedKingdom">United Kingdom</option>
                <option value="NorthAmerica">North America</option>
                <option value="World">Global</option>
                <option value="Cornwall">Cornwall</option>
                <option value="CaliforniaCormorants">CA Cormorant Range</option>
                <option value="CaliforniaOsprey">CA Osprey Range</option>
                <option value="SantaClaraAcrage">CA Santa Clara</option>
                <option value="CaliforniaPublicSalesandTax">CA Sales & Tax</option>
                <option value="DetroitIncome">MI Detroit Median Income</option>
            </select>
        </div>
    );
};

export default MetricDropdown;
