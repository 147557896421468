import React, { useEffect, useState } from 'react';
import { IoClose } from "react-icons/io5";
import { BrowserView, MobileView, isMobile, isTablet } from 'react-device-detect';

const ClickedInfo = ({
  isVisible,
  polygonCustomLayerRef,
  clickedInfoHeader,
  globalState,
  longstandingGeoJSONRef
}) => {
  // State to manage the visibility of the more information section
  const [showMore, setShowMore] = useState(true);

  let selectedYearEditing = globalState.selectedYearEditing;
  let selectedMetric = globalState.selectedMetric;

  let mobileStatus = isMobile ? "mobile" : "not-mobile";

  if (isTablet) {
    mobileStatus = "not-mobile";
  }

  const [modalOther, setModalOther] = useState(null);
  const [modalSkylarkId, setModalSkylarkId] = useState(null);
  const [selectedMetricValue, setSelectedMetricValue] = useState(null); // State for the selected metric's value

  const geojson = longstandingGeoJSONRef.current;

  function roundToTwoDecimals(value) {
    if (typeof value === 'number') {
      return Math.round(value * 100) / 100;
    }
    return value;
  }

  // Function to format and show all available metrics and data for the selected year
  function formatAllMetricsForYear(featureDataTimeseries, selectedYear) {
    let availableMetrics = Object.keys(featureDataTimeseries);
    let yearDataExists = false;

    for (let i = 0; i < availableMetrics.length; i++) {
      if (availableMetrics[i] === 'coordinates') {
        availableMetrics.splice(i, 1);
      }
    }

    return (
      <table className='clickedInfoTable'>
        <thead>
          <tr></tr>
        </thead>
        <tbody>
          {availableMetrics.map((metric) => {
            const yearData = featureDataTimeseries[metric][selectedYear];
            const truncatedMetric = metric.substring(0, 20); // Truncate metric name

            // Truncate the value to 10 characters if it's a string, or keep it as is for numbers
            const displayValue = (yearData !== undefined)
              ? (typeof yearData === 'string' ? yearData.substring(0, 10) : roundToTwoDecimals(yearData))
              : "No data available";

            if (yearData !== undefined) {
              yearDataExists = true;
              return (
                <tr key={metric}>
                  <td className="clickedInfoLeftHandColumn">{truncatedMetric}:</td>
                  <td className="clickedInfoRightHandColumn">{displayValue}</td>
                </tr>
              );
            } else {
              return (
                <tr key={metric}>
                  <td className="clickedInfoLeftHandColumn">{truncatedMetric}:</td>
                  <td className="clickedInfoRightHandColumn">No data available</td>
                </tr>
              );
            }
          })}
          {!yearDataExists && (
            <tr>
              <td colSpan="2">No data available for the selected year</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  useEffect(() => {
    if (isVisible && clickedInfoHeader !== null) {
      for (let i = 0; i < geojson.features.length; i++) {
        if (geojson.features[i].skylark_id === clickedInfoHeader) {
          // Access the timeseries data for all metrics
          const timeseriesData = geojson.features[i].feature_data_simple_timeseries;

          // Get value for the selectedMetric in the selectedYear
          const metricValue = timeseriesData[selectedMetric]?.[selectedYearEditing];
          const formattedValue = metricValue !== undefined ? roundToTwoDecimals(metricValue) : "No data available";
          setSelectedMetricValue(formattedValue);

          // No longer limiting to a specific metric, we now handle all metrics
          const formattedDataForYear = formatAllMetricsForYear(timeseriesData, selectedYearEditing);
          setModalOther(formattedDataForYear);

          // Display a truncated skylark ID
          const truncatedSkylarkId = geojson.features[i].skylark_id.substring(0, 15); // Truncate skylark_id
          setModalSkylarkId(truncatedSkylarkId + '...');
        }
      }
    }
  }, [clickedInfoHeader, selectedYearEditing, selectedMetric]);

  return (
    isVisible && (
      <div className={`clicked_info ${mobileStatus}`}>

        <MobileView>
          <div className='clicked_info_header'>
            <div className='clicked_info_close'>
              <IoClose className="clicked_info_close_icon" size={20} onClick={() => polygonCustomLayerRef.current.handleCloseClickedInfoCancel()} />
            </div>
          </div>
          {showMore && (
            <div className='clicked_info_inner'>
              {selectedMetric && (
                <>
                  <h1>{selectedMetric}</h1>
                  <h2>{selectedMetricValue}</h2>
                </>
              )}
              <div className='clicked_info_other'>
                {modalOther}
              </div>
            </div>
          )}
        </MobileView>

        <BrowserView>
          <div className='clicked_info_header'>
            <div className='clicked_info_close'>
              <IoClose className="clicked_info_close_icon" size={20} onClick={() => polygonCustomLayerRef.current.handleCloseClickedInfoCancel()} />
            </div>
          </div>
          {showMore && (
            <div className='clicked_info_inner'>
              {selectedMetric && (
                <>
                  <h1>{selectedMetric}</h1>
                  <h2>{selectedMetricValue}</h2>
                </>
              )}
              <div className='clicked_info_other'>
                {modalOther}
              </div>
            </div>
          )}
        </BrowserView>
      </div>
    )
  );
};

export default ClickedInfo;
